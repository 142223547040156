import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { CheckBox } from '@mui/icons-material';
import './archive.css'
import axios from 'src/api/axios';
import { ArchiveCont, DestroyNotifications, WaiterArchive } from 'src/constants/url';
import { Spinner } from 'react-bootstrap';
import first from './first_1041006 1.svg'
import second from './first_1041006 2.svg'
import third from './first_1041006 3.svg'

export default function FixedSizeGridWaiters() {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [data , SetData] = React.useState([])
  const FetchArchive = async () => {
      await axios.post(WaiterArchive , {} , {
          headers : {
              Accept: 'application/json',
          }
      }).then(res => {
            if(res.status === 200){
              let responseVALUES = Object.values(res.data.data.summary)
              let responseNAMES = Object.keys(res.data.data.summary)
                for(let i = 0 ; i < responseVALUES.length ; i++) {
                  responseVALUES[i].name = responseNAMES[i]
                  responseVALUES[i].id = i + 1
                }
                SetData(responseVALUES)
            }
      }).finally(()=> {
        
      })
  }
  const [deleteLoad , setDeleteLoad] = React.useState(false);
  const [showSure , setShowSure] = React.useState(false)
  React.useEffect(() => {
      FetchArchive()
  },[deleteLoad])
  const myData = [
    { id: 1, order_No: 1, table_No: 'Item 1', waiter: 'Jasone', Time: '19:00 2/2/2023' },
    { id: 2, order_No: 2, table_No: 'Item 2', waiter: 'Jasone', Time: '19:00 2/2/2023' },
    { id: 3, order_No: 3, table_No: 'Item 3', waiter: 'Jasone', Time: '19:00 2/2/2023' },
    // Add more data as needed
  ];

  const columns = [
    { field: 'rank',
      headerName: '#', 
      flex : 1 , 
      minWidth : 50  , 
      maxWidth :  60 ,
      renderCell: (params) => {
        const id = params.row.id;
        let content;
  
        if (id === 1) {
          content = <img className='img-steps' src={first} alt="Image 1" width="20" height="20" />;
        } else if (id === 2) {
          content = <img className='img-steps' src={second} alt="Image 2" width="20" height="20" />;
        } else if (id === 3) {
          content = <img className='img-steps' src={third} alt="Image 3" width="20" height="20" />;
        } else {
          content = id;
        }
  
        return content;
      },
    },
    { field: 'name', headerName: "Waiter's Name", flex : 1 , minWidth : 50  },
    { field: 'help', headerName: 'Help Serviced', flex : 1 , minWidth : 50  },
    { field: 'bill', headerName: 'Bill Serviced', flex : 1 , minWidth : 50  },
    { field: 'done', headerName: 'Order Serviced', flex : 1 , minWidth : 50  },
    { field: 'total', headerName: 'Total Serviced', flex : 1 ,minWidth : 50  },
  ];

  const handleCheckboxClick = (row) => {
    // Toggle the selection status of the clicked row
    if (selectedRows.includes(row.id)) {
      setSelectedRows(selectedRows.filter((id) => id !== row.id));
    } else {
      setSelectedRows([...selectedRows, row.id]);
    }
  };
  const DeleteNotifications = async ()=> {
    setDeleteLoad(true)
    await axios.post(DestroyNotifications,{
      ids : selectedRows
    },{
      headers : {
        Accept: 'application/json',
      }
    }).then(res => {
      if(res.status === 200){
        setSelectedRows([])
        setClickedOrder(0)
        setDeleteLoad(false)
      }
    })
  }
  return (
    <div className='table-container' style={{ width: '100%' , background : "white" ,padding : "20px" }}>
        <p className="title-mui">All  Waiters</p>
      <div style={{ height: 350, width: '100%' }}>
        <DataGrid style={{width : "100%"}} rows={data} columns={columns} />
      </div>
    </div>
  );
}
