import { Helmet } from 'react-helmet-async';
import '../custom.css'
import {
  Typography,
  Container,
  Stack,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'src/api/axios';
import { useState, useEffect } from 'react';
import { PACKAGES, STORAGE } from 'src/constants/url';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeletePackage from './DeletePackage';
import CreatePackage from './CreatePackage';
import UpdatePackage from './UpdatePackage';
import Label from 'src/components/label/Label';
import ShowItemsPopUp from './ShowItemsPopUp';



const PackagesPage = () => {
  const roles = localStorage.getItem('permissions');
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);

  const Fetchpkgs = async () => {
    setLoading(true);
      await axios
        .get(PACKAGES, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setInfo(response.data.data);
          } else if (response.status === 401) {
            navigate('/', { replace: true });
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    setLoading(false);
  };
  useEffect(() => {
    Fetchpkgs();
  }, [reload]);
  return (
    <>
      <Helmet>
        <title> Packages | Fenice Palas </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Packages
          </Typography>
          {roles.includes('create-Item') && <CreatePackage setReload={setReload} reload={reload} />}
        </Stack>
        {loading ? (
          <></>
        ) : (
          <>
            {info.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{
                  '& .MuiPaper-root ': {
                    overflowX: 'scroll',
                  },
                }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Image</TableCell>
                      <TableCell align="center">Price</TableCell>
                      <TableCell align="center">Offer Price</TableCell>
                      {/* <TableCell align="center">Category</TableCell>
                      <TableCell align="center">Price</TableCell>
                      <TableCell align="center">Offer Price</TableCell>
                      <TableCell align="center">Weight</TableCell>
                      <TableCell align="center">Description</TableCell>
                      <TableCell align="center">Tags</TableCell> */}
                      <TableCell align='center'>Items</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {info.map((pkg) => (
                      <TableRow key={pkg.id} id={pkg.id}>
                        {/* <TableCell align="center">{pkg.id}</TableCell> */}
                        <TableCell align="center">{pkg.name}</TableCell>
                        <TableCell align="center">
                          <Link to={`${STORAGE}/${pkg.image}`} target="_bank">
                            <VisibilityIcon />
                          </Link>{' '}
                        </TableCell>
                        <TableCell align="center">{pkg.price}</TableCell>
                        <TableCell align="center" width={"30px"}>{pkg.offer_price}</TableCell>
                        <TableCell align='center' width={"300px"}>
                          <ShowItemsPopUp items={pkg.items}/>
                        </TableCell>
                        <TableCell align="center" width={"30px"}>
                          <Stack>
                            {roles.includes('update-Item') && (
                              <UpdatePackage id={pkg.id} i={pkg} setReload={setReload} reload={reload} />
                            )}
                            {roles.includes('delete-Item') && (
                              <DeletePackage id={pkg.id} setReload={setReload} reload={reload} />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
                {' '}
                No packages yet{' '}
              </Typography>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default PackagesPage;
