import { useState, useEffect } from 'react';
import axios from 'src/api/axios';
import { CATEGORIES } from 'src/constants/url';
import { InputLabel, MenuItem, FormControl, Select, Skeleton } from '@mui/material';
const CategorySection = (props) => {
  const [info, setInfo] = useState([]);
  const [infoLoaded, setInfoLoaded] = useState(false);

  const handleChange = (e) => {
    props.setcategory_section(e.target.value);
  };
  const FetchCategorySection = async () => {
    await axios
      .get(CATEGORIES, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
        }
      })
      .catch(error => {
        // Handle errors
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          console.log('Response status:', error.response.status);
          console.log('Response data:', error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Request was made but no response received:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error setting up the request:', error.message);
        }
        console.log('Error config:', error.config);
      })
      .finally(() => {
        setInfoLoaded(true);
      });
  };
  useEffect(() => {
    FetchCategorySection();
  }, []);
  return !infoLoaded ? (
    <Skeleton width={'100%'} height={'5rem'} />
  ) : (
    <FormControl variant="filled" fullWidth className="mt-2">
      <InputLabel id="demo-simple-select-filled-label">Select Category</InputLabel>
      <Select
      // multiple
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={[props.category_section]}
        onChange={(e) => props.setcategory_section(e.target.value)}
        name="category_section"
      >
        {info.length > 0 ? (
          info.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {item.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem>No Category</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default CategorySection;
