import ScrollToTop from './components/scroll-to-top';
import './assets/css/style.css';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard/DashboardLayout';
import DashboardAppPage from './pages/DashboardAppPage';
import CategoryPage from './pages/categories/CategoryPage';
import LoginPage from './pages/LoginPage';
import PrivateRoutes from './PrivateRoutes';
import UserPage from './pages/user/UserPage';
import ItemsPage from './pages/items/ItemsPage';
import KitchenPage from './pages/kitchen/KitchenPage';
import TablesPage from './pages/tables/TablesPage';
import Page404 from './pages/Page404';
import AboutPage from './pages/about/AboutPage';
import { onMessageListener, requestForToken } from './firebase';
import { useState, useEffect, useRef } from 'react';
import toast, { Toaster, ToastBar } from 'react-hot-toast';
import { NOTIFICATIONS } from './constants/url';
import axios from 'src/api/axios';
import Unauthorized from './pages/Unauthorized';
import RequireAuth from './components/RequireAuth';
import AddLanguage from './pages/languages/AddLanguage';
import CurrencyPage from './pages/currency/CurrencyPage';
import PackagesPage from './pages/packeages/PackagesPage';
import './assets/all.min.css';
import Order from './pages/tables/OrderPage';
import Archive from './pages/archive/Archive';
import ArchiveNotificatios from './pages/archive/ArchiveNotificatios';
import ArchiveWaiters from './pages/archive/ArchiveWaiters';
import Qr from './pages/qr/Qr';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

export default function App() {
  const [notification, setNotification] = useState({ body: '' });
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div>
        <p style={{ wordBreak: 'break-all' }}>{notification?.body}</p>
      </div>
    );
  }
  const FetchNotifications = async () => {
      setLoading(true); // Assuming setLoading is a function that sets loading state to true
      
      try {
        const response = await axios.get(NOTIFICATIONS, {
          headers: {  
            Accept : "application/json",
            "Content-Type" : "application/json"
          },
      });
      
      setLoading(false); // Assuming setLoading is a function that sets loading state to false
      if (response.status === 200) {
        setNotifications(response.data.data); // Assuming setNotifications is a function to update notifications state
      }
    } catch (error) {
      console.log(error);
      }
    };
  onMessageListener()
  .then((payload) => {
    FetchNotifications()
    if(payload.notification !== null) {
      if(payload.notification.title === "EndSession") {
        return(payload)
      }else {
        return null
      }
    }
    }).then(payload=>{
   
    })
  .catch((err) => console.log('failed: ', err));
  useEffect(()=>{
    requestForToken()
  },[])
  return (
    <>

      <Toaster
        position="left-bottom"
        toastOptions={{
          className: '',
          style: {
            width: '500px',
            padding: '16px',
            color: '#fff',
            background: '#CDA34F',
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== 'loading' && (
                  <button className="toast-close" onClick={() => toast.dismiss(t.id)}>
                    X
                  </button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<DashboardLayout notifications={notifications} loading={loading} setLoading={setLoading} FetchNotifications={FetchNotifications} />}>
              <Route path="app" element={<DashboardAppPage />} />
              <Route path="languages" element={<AddLanguage />} />

              <Route element={<RequireAuth allowedRoles={['Show-About']} />}>
                <Route path="about" element={<AboutPage />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={['create-Table']} />}>
                <Route path="QR-Codes" element={<Qr />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={['show-Category']} />}>
                <Route path="categories" element={<CategoryPage  />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['show-Item']} />}>
                <Route path="items" setNotification element={<ItemsPage />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={['show-Item']} />}>
                <Route path="packages" element={<PackagesPage/>} />
              </Route>

              <Route element={<RequireAuth allowedRoles={['show-StaffSection']} />}>
                <Route path="staff-section" element={<KitchenPage />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['show-User']} />}>
                <Route path="users" element={<UserPage />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={['show-Table']} />}>
                <Route path="tables" element={<TablesPage FetchNotifications={FetchNotifications} />} />
                 {/* <Route path="tables" element={<OneTable />} /> */}
                 {/* <Route path="tables" element={<OneTable />} /> */}
              </Route>

              <Route element={<RequireAuth allowedRoles={['Show-Order']} />}>
                <Route path="orders" element={<Order FetchNotifications={FetchNotifications} />} />
              </Route>
                <Route path="archive" element ={<Archive/>} />
                <Route path="archive/archive-notifications" element ={<ArchiveNotificatios/>} />
                <Route path="archive/archive-waiters" element ={<ArchiveWaiters/>} />
              <Route path="currency" element={<CurrencyPage />} />
            </Route>

          </Route>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route element={<LoginPage FetchNotifications={FetchNotifications} />} path="/login"/>
          <Route element={<Page404 />} path="*" />
          <Route path="unauthorized" element={<Unauthorized />} />
        </Routes>
      </ThemeConfig>
    </>
  );
}