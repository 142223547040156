import { useState } from 'react';
import './showitems.css';
function ShowItemsPopUp ({items}) {
    const [show , setShow] = useState(false);
    return (
        <div className="pop-container">
            <div className={!show ? "overlay display-none-pop" : "overlay"}  onClick={()=>setShow(false)}></div>
            <div className="button-pop-up" onClick={()=>setShow(true)}>Show Items</div>
            <div className={!show ? "items-pop-up display-none-pop" : "items-pop-up"}>
                <div className="exit-icon" onClick={()=>setShow(false)}>&#10005;</div>
                <p>Items Included </p>
                <div className="items-container">
                <ul>
                    {
                        items.map((e,i)=> {
                            return (
                                <li key={i}>{e.name}</li>
                                )
                            })
                        }
                </ul>
                        </div>
                <span onClick={()=>setShow(false)}>OK</span>
            </div>
        </div>
    )
}
export default ShowItemsPopUp;