import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';
import { Link, useNavigate } from "react-router-dom";

import axios from 'src/api/axios';
import { LOGOUT } from 'src/constants/url';
import { Spinner } from 'react-bootstrap';
import Iconify from 'src/components/iconify';
export default function AccountPopover() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const name = localStorage.getItem("userName");
  const email = localStorage.getItem("uesrEmail");
  const roles =  localStorage.getItem('role_name');
  const permission =  localStorage.getItem('permissions');
  const logOut = () => {
    const headers = {
      Accept: "application/json",
    };
    const data = {};
    axios
      .post(LOGOUT, data, { headers })
      .then((response) => {
        localStorage.removeItem("userName");
        localStorage.removeItem("uesrEmail");
        localStorage.removeItem('role_name');
        localStorage.removeItem('permissions');
        localStorage.removeItem('role_name');
        localStorage.removeItem('index_table');
        localStorage.removeItem('Table_Noti_Type');
        setSpin(0)
        navigate("/login");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate('/login')
        }

      });
  };
  const [spin , setSpin] = useState(0)
  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
      
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 300,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.65,
            },
          },
        }}
      >
        <div style={{padding : "20px"}}>
          <div style={{display : "flex" , alignItems : "center"}}>
            <svg style={{width : "83px" , height : "83px"}} xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 86 86" fill="none">
              <path d="M43 84.6562C66.0061 84.6562 84.6562 66.0061 84.6562 43C84.6562 19.9939 66.0061 1.34375 43 1.34375C19.9939 1.34375 1.34375 19.9939 1.34375 43C1.34375 66.0061 19.9939 84.6562 43 84.6562Z" fill="#CDA34F" fillOpacity="0.15"/>
              <path d="M76.4285 67.8255C72.5435 62.6242 67.4988 58.4007 61.6954 55.4908C55.892 52.5808 49.4899 51.0645 42.9978 51.0625C36.5057 51.0605 30.1027 52.5727 24.2974 55.479C18.4922 58.3854 13.4449 62.6057 9.55664 67.8046C13.4252 73.0298 18.4642 77.2759 24.2698 80.2024C30.0753 83.1288 36.4858 84.6543 42.9873 84.6563C49.4887 84.6583 55.9002 83.1369 61.7076 80.2141C67.515 77.2913 72.5567 73.0484 76.4285 67.8255Z" fill="url(#paint0_linear_2073_1414)"/>
              <path d="M43 45.6875C51.9056 45.6875 59.125 38.4681 59.125 29.5625C59.125 20.6569 51.9056 13.4375 43 13.4375C34.0944 13.4375 26.875 20.6569 26.875 29.5625C26.875 38.4681 34.0944 45.6875 43 45.6875Z" fill="url(#paint1_linear_2073_1414)"/>
              <defs>
                <linearGradient id="paint0_linear_2073_1414" x1="42.9926" y1="51.0625" x2="42.9926" y2="84.6563" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#CA9D4A"/>
                  <stop offset="1" stopColor="#E0DA97"/>
                </linearGradient>
                <linearGradient id="paint1_linear_2073_1414" x1="43" y1="13.4375" x2="43" y2="45.6875" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#CA9D4A"/>
                  <stop offset="1" stopColor="#E0DA97"/>
                </linearGradient>
              </defs>
            </svg>
            <div style={{marginLeft : "12px"}}>
            <Typography className='email-logOut' variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {email}
              </Typography>
              <Typography className='name-logOut' variant="subtitle2" noWrap>
                {name}
              </Typography>
            </div>
            </div>
        </div>
        <hr className='hr-logOut'/>
        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}
        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}
        <MenuItem style={{display : "flex" , justifyContent : "start" , alignItems : "center"}} onClick={()=>{logOut() ; setSpin(1)}} sx={{ m: 1 }}>
          {spin ? <Spinner animation="border" style={{width : "25px" , height : "25px" , margin : "0 auto" , color : "rgba(205, 163, 79, 0.96)"}}/> :
          <div style={{display : "flex" , justifyContent :"space-around"}}>
            <svg style={{marginLeft : "20px"}} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
              <path d="M14.0833 2.16667C11.0918 2.16667 8.66663 4.5918 8.66663 7.58334C8.66663 8.18164 9.15165 8.66667 9.74996 8.66667C10.3483 8.66667 10.8333 8.18164 10.8333 7.58334C10.8333 5.78842 12.2884 4.33334 14.0833 4.33334H18.4166C20.2116 4.33334 21.6666 5.78842 21.6666 7.58334V18.4167C21.6666 20.2116 20.2116 21.6667 18.4166 21.6667H14.0833C12.2884 21.6667 10.8333 20.2116 10.8333 18.4167C10.8333 17.8183 10.3483 17.3333 9.74996 17.3333C9.15165 17.3333 8.66663 17.8183 8.66663 18.4167C8.66663 21.4082 11.0918 23.8333 14.0833 23.8333H18.4166C21.4081 23.8333 23.8333 21.4082 23.8333 18.4167V7.58334C23.8333 4.5918 21.4081 2.16667 18.4166 2.16667H14.0833Z" fill="url(#paint0_linear_2073_1441)"/>
              <path d="M15.1665 11.9167C15.7648 11.9167 16.2498 12.4017 16.2498 13C16.2498 13.5983 15.7648 14.0833 15.1665 14.0833V11.9167Z" fill="url(#paint1_linear_2073_1441)"/>
              <path d="M6.19434 11.9167C6.29078 11.7977 6.38317 11.6823 6.47058 11.5722C6.73653 11.2367 6.96169 10.9421 7.12068 10.731C7.20025 10.6253 7.26343 10.5403 7.30705 10.4812L7.3575 10.4126L7.37102 10.3941L7.37581 10.3875C7.37586 10.3875 7.37635 10.3868 6.49993 9.75001L7.37581 10.3875C7.72748 9.90348 7.62073 9.22525 7.13669 8.87357C6.65268 8.52191 5.97524 8.62919 5.62354 9.11317L5.62049 9.11736L5.60939 9.13253L5.56415 9.19403C5.52414 9.24823 5.46496 9.32787 5.38978 9.42772C5.23927 9.62761 5.02533 9.90755 4.77283 10.226C4.26232 10.8698 3.61575 11.6438 3.02556 12.234L2.25952 13L3.02556 13.766C3.61575 14.3562 4.26232 15.1302 4.77283 15.774C5.02533 16.0925 5.23927 16.3724 5.38978 16.5723C5.46496 16.6722 5.52414 16.7518 5.56415 16.806L5.60939 16.8675L5.62049 16.8827L5.62301 16.8861C5.9747 17.3701 6.65268 17.4781 7.13669 17.1264C7.62073 16.7748 7.72804 16.0973 7.37635 15.6132L6.49993 16.25C7.37635 15.6132 7.37641 15.6133 7.37635 15.6132L7.37102 15.606L7.3575 15.5874L7.30705 15.5189C7.26343 15.4597 7.20025 15.3748 7.12068 15.269C6.96169 15.0579 6.73653 14.7633 6.47058 14.4278C6.38317 14.3177 6.29078 14.2023 6.19434 14.0833H15.1666V11.9167H6.19434Z" fill="url(#paint2_linear_2073_1441)"/>
              <defs>
                <linearGradient id="paint0_linear_2073_1441" x1="16.25" y1="2.92958" x2="16.25" y2="23.8333" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#CDA34F"/>
                  <stop offset="1" stopColor="#A77C27"/>
                </linearGradient>
                <linearGradient id="paint1_linear_2073_1441" x1="15.7082" y1="11.993" x2="15.7082" y2="14.0833" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#CDA34F"/>
                  <stop offset="1" stopColor="#A77C27"/>
                </linearGradient>
                <linearGradient id="paint2_linear_2073_1441" x1="8.71305" y1="8.97176" x2="8.71305" y2="17.3331" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#CDA34F"/>
                  <stop offset="1" stopColor="#A77C27"/>
                </linearGradient>
              </defs>
            </svg>
            <span className='span-logOut' style={{display : "block"}}>Logout</span>
          </div>
          }
        </MenuItem>
      </Popover>
    </>
  );
}
