import { addDays } from 'date-fns';
import { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default function DatePicker2({setWaitersDataWithRequest2}) {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 13),
      key: 'selection',
    },
  ]);

  const handleDateChange = (item) => {
    setState([item.selection]);

    const formattedStartDate = formatDate(item.selection.startDate);
    const formattedEndDate = formatDate(item.selection.endDate);
    setWaitersDataWithRequest2({
      startDate : formattedStartDate,
      endDate : formattedEndDate
    })
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <DateRangePicker
      rangeColors={["#cda34f"]}
      onChange={handleDateChange}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={state}
      direction="horizontal"
    />
  );
}
