import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useEffect, useState } from 'react';
import axios from 'src/api/axios';
import {
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Skeleton,
} from '@mui/material';
import { ADD_LANG, KITCHEN_SECTION } from 'src/constants/url';

const UpdateKitchen = ({ reload, setReload, id, i }) => {
  const [open, setOpen] = useState(false);
  const [loadingLang, setLoadingLang] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [nameEn, setNameEn] = useState(i.name);
  const [error, setError] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const UpdateStaffSectionForm = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append(`name`, nameEn);
    axios
      .post(`${KITCHEN_SECTION}/${id}?_method=PUT`, formData, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setMessage(true);
        setTimeout(() => {
          setReload(!reload);
        }, 1500);
        setOpen(false);
        setError(false);
      })
      .catch((error) => {
        setError(true);
        showErrorAlert(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen} className="btn-bg border-0 text-white">
        <ModeEditOutlineOutlinedIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update Resturant Section</DialogTitle>
        <DialogContent>

          {error ? (
            <Alert severity="error" className="mb-4">
              <AlertTitle>Error</AlertTitle>
              <p>{erroralert}</p>
            </Alert>
          ) : (
            ''
          )}
          {loadingLang ? <Skeleton animation="wave" width="300px" /> :
            <>
              {
                <TextField
                  id="filled-basic"
                  label={`Staff Section Name`}
                  value={nameEn}
                  variant="filled"
                  fullWidth
                  onChange={(e) => setNameEn(e.target.value)}
                  className="mb-2"
                />
              }
            </>
          }


        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={UpdateStaffSectionForm}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' , overflowX : "hidden"  }}

        >
          success! Kitchen Section Updated Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default UpdateKitchen;
