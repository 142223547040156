import { Button, Container, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'src/api/axios';
import { ADD_LANG, AVILABLE_LANG } from 'src/constants/url';
import translation from './translation.json';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoIcon from '@mui/icons-material/Info';

const AddLanguage = () => {
  const [lang, setLang] = useState('');
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState([]);
  // const [all, setAll] = useState([]);
  const [homeTitle, setHomeTitle] = useState('');
  const [langTitle, setLangTitle] = useState('');
  const [homeLink, setHomeLink] = useState('');
  const [orderLink, setOrderLink] = useState('');
  const [searchLink, setSearchLink] = useState('');
  const [aboutLink, setAboutLink] = useState('');
  const [catTitle, setCatTitle] = useState('');
  const [trend, setCatTrend] = useState('');
  const [noCat, setNoCat] = useState('');
  const [itemTitle, setItemTitle] = useState('');
  const [addBtn, setAddBtn] = useState('');
  const [noteText, setNoteText] = useState('');
  const [note, setNote] = useState('');
  const [noItem, setNoItem] = useState('');
  const [filterTitle, setFilterTitle] = useState('');
  const [allFilter, setAllFilter] = useState('');
  const [veganFilter, setVeganFilter] = useState('');
  const [lactoseFilter, setLactoseFilter] = useState('');
  const [glutenFilter, setGlutenFilter] = useState('');
  const [curTitle, setCurTitle] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [yesButton, setYesButton] = useState('');
  const [noButton, setNoButton] = useState('');
  const [orderStat, setOrderStat] = useState('');
  const [quantity, setQuantity] = useState('');
  const [noOrder, setNoOrder] = useState('');
  const [aboutTitle, setAboutTitle] = useState('');
  const [followTitle, setFollowTitle] = useState('');
  const [noAbout, setNoAbout] = useState('');
  const [nosearch, setNoSearch] = useState('');
  const [waitMess, setWaitMess] = useState('');
  const [successMess, setSuccessMess] = useState('');
  const [btnSub, setBtnSub] = useState('');
  const [title, setTitle] = useState('');
  const handleChangeLang = (event) => {
    setLang(event.target.value);
  };

  const [all, setAll] = useState([]);

  const handleroom = (e, id) => {
    const { value } = e.target;
    setAll((room) =>
      room?.map((list, index) =>
        list.id === id ? { ...list, room: value } : list,
      )
    );
  };

  const [inputs, setInputs] = useState({});
  const CreateLangForm = () => {
    const tr_obj = {
      home_title: homeTitle,
      language_title: langTitle,
      home_link: homeLink,
      myOrder_link: orderLink,
      search_link: searchLink,
      about_link: aboutLink,
      cat_title: catTitle,
      Trended: trend,
      NoInformationCat: noCat,
      item_title: itemTitle,
      AddNoteButton: addBtn,
      AddNoteText: noteText,
      note_text: note,
      NoInformationItems: noItem,
      filter_title: filterTitle,
      all: allFilter,
      vegan: veganFilter,
      lactose_free: lactoseFilter,
      gluten_free: glutenFilter,
      currency_title: curTitle,
      modal_title: modalTitle,
      yesButton: yesButton,
      noButton: noButton,
      Order_Status: orderStat,
      Quantity: quantity,
      NoInformationOrder: noOrder,
      about_title: aboutTitle,
      Follow: followTitle,
      NoInformationAbout: noAbout,
      NoInformationSearch: nosearch,
      message_wait: waitMess,
      success_message: successMess,
      button_send: btnSub
    }
    const formData = new FormData();
    formData.append(`title[${lang}]`, lang);
    const res = all.map((item) => formData.append(`title[${item.code}]`, [item.room]));

    formData.append("tr_obj", JSON.stringify(tr_obj));


    axios
      .post(ADD_LANG, formData, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        // setMessage(true);
        // setTimeout(() => {
        //   setReload(!reload);
        // }, 1500);
        // setOpen(false);
        // setError(false);
      })

      .catch((error) => {
        // setError(true);
        // showErrorAlert(error.response.data.message);
        // setEmpty('please add kitchen section then try to add category');
        console.log(error);
      });

  };



  return (
    <>
      <Helmet>
        <title> Language | Fenice Palas </title>
      </Helmet>
      <Container className='lang-inputs'>
        <Typography variant="h4" className="mb-4">
          Add New Language
        </Typography>
        {!loading ? (
          <Skeleton width={'100%'} height={'5rem'} />
        ) : (

          <FormControl variant="filled" fullWidth className='mb-4'>
            <InputLabel id="demo-simple-select-filled-label">New Language</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={lang}
              onChange={handleChangeLang}
            >
              {info.length > 0 ? (
                info.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No Languages</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        {all.length > 0 && all.map((item, i) => (
          <TextField
            id={item.id}
            label={item.title}
            value={item.room}
            key={item.code}
            variant="filled"
            fullWidth
            onChange={(e) => handleroom(e, item.id)}
          />
        ))}

        <Typography variant="h6" className="mb-2">
          Home Page Translation
        </Typography>
        <Grid Grid container spacing={2} className="mb-4">
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Home Page Title"
              value={homeTitle}
              variant="filled"
              fullWidth
              onChange={(e) => setHomeTitle(e.target.value)}
            />
            <Tooltip title="Defualt: Fenice Palas Menu">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Language Title"
              value={langTitle}
              variant="filled"
              fullWidth
              onChange={(e) => setLangTitle(e.target.value)}
            />
            <Tooltip title="Defualt: Language">
              <InfoIcon />
            </Tooltip>
          </Grid>
        </Grid>
        <Typography variant="h6" className="mb-2">
          Links Bottom Translation
        </Typography>
        <Grid Grid container spacing={2} className="mb-4">
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Home Link"
              value={homeLink}
              variant="filled"
              fullWidth
              onChange={(e) => setHomeLink(e.target.value)}
            />
            <Tooltip title="Defualt: home">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="My Order Link"
              value={orderLink}
              variant="filled"
              fullWidth
              onChange={(e) => setOrderLink(e.target.value)}
            />
            <Tooltip title="Defualt: my order">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Search Link"
              value={searchLink}
              variant="filled"
              fullWidth
              onChange={(e) => setSearchLink(e.target.value)}
            />
            <Tooltip title="Defualt: search">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="About Link"
              value={aboutLink}
              variant="filled"
              fullWidth
              onChange={(e) => setAboutLink(e.target.value)}
            />
            <Tooltip title="Defualt: about us">
              <InfoIcon />
            </Tooltip>
          </Grid>
        </Grid>
        <Typography variant="h6" className="mb-2">
          Category Page Translation
        </Typography>
        <Grid Grid container spacing={2} className="mb-4">
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="CategoryPage Title"
              value={catTitle}
              variant="filled"
              fullWidth
              onChange={(e) => setCatTitle(e.target.value)}
            />
            <Tooltip title="Defualt: Our categories">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Trend Category"
              value={trend}
              variant="filled"
              fullWidth
              onChange={(e) => setCatTrend(e.target.value)}
            />
            <Tooltip title="Defualt: Trended today">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="No Categories"
              value={noCat}
              variant="filled"
              fullWidth
              onChange={(e) => setNoCat(e.target.value)}
            />
            <Tooltip title="Defualt: NO Categories">
              <InfoIcon />
            </Tooltip>
          </Grid>
        </Grid>
        <Typography variant="h6" className="mb-2">
          Items Page Translation
        </Typography>
        <Grid Grid container spacing={2} className="mb-4">
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Items Page Title"
              value={itemTitle}
              variant="filled"
              fullWidth
              onChange={(e) => setItemTitle(e.target.value)}
            />
            <Tooltip title="Defualt: Fenice Palas restaurant">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Add Note Button"
              value={addBtn}
              variant="filled"
              fullWidth
              onChange={(e) => setAddBtn(e.target.value)}
            />
            <Tooltip title="Defualt: Add Note">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Note"
              value={note}
              variant="filled"
              fullWidth
              onChange={(e) => setNote(e.target.value)}
            />
            <Tooltip title="Defualt: Note">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Add your note "
              value={noteText}
              variant="filled"
              fullWidth
              onChange={(e) => setNoteText(e.target.value)}
            />
            <Tooltip title="Defualt: Add your note">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="No Items"
              value={noItem}
              variant="filled"
              fullWidth
              onChange={(e) => setNoItem(e.target.value)}
            />
            <Tooltip title="Defualt: No Items">
              <InfoIcon />
            </Tooltip>
          </Grid>
        </Grid>
        <Typography variant="h6" className="mb-2">
          Filter Section Translation
        </Typography>
        <Grid Grid container spacing={2} className="mb-4">
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Filter Title"
              value={filterTitle}
              variant="filled"
              fullWidth
              onChange={(e) => setFilterTitle(e.target.value)}
            />
            <Tooltip title="Defualt: Filter">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="All Select"
              value={allFilter}
              variant="filled"
              fullWidth
              onChange={(e) => setAllFilter(e.target.value)}
            />
            <Tooltip title="Defualt: All">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Vegan Select"
              value={veganFilter}
              variant="filled"
              fullWidth
              onChange={(e) => setVeganFilter(e.target.value)}
            />
            <Tooltip title="Defualt: vegan">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Lactose Free Select"
              value={lactoseFilter}
              variant="filled"
              fullWidth
              onChange={(e) => setLactoseFilter(e.target.value)}
            />
            <Tooltip title="Defualt: lactose free">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Gluten Free Select"
              value={glutenFilter}
              variant="filled"
              fullWidth
              onChange={(e) => setGlutenFilter(e.target.value)}
            />
            <Tooltip title="Defualt: gluten free">
              <InfoIcon />
            </Tooltip>
          </Grid>
        </Grid>
        <Typography variant="h6" className="mb-2">
          Currency Section Translation
        </Typography>
        <Grid Grid container spacing={2} className="mb-4">
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Currency Title"
              value={curTitle}
              variant="filled"
              fullWidth
              onChange={(e) => setCurTitle(e.target.value)}
            />
            <Tooltip title="Defualt: Select Currency">
              <InfoIcon />
            </Tooltip>
          </Grid>
        </Grid>
        <Typography variant="h6" className="mb-2">
          Order Page Translation
        </Typography>
        <Grid Grid container spacing={2} className="mb-4">
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Modal Title"
              value={modalTitle}
              variant="filled"
              fullWidth
              onChange={(e) => setModalTitle(e.target.value)}
            />
            <Tooltip title="Defualt: Is this the last order?">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Yes Button"
              value={yesButton}
              variant="filled"
              fullWidth
              onChange={(e) => setYesButton(e.target.value)}
            />
            <Tooltip title="Defualt: Yes">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="No Button"
              value={noButton}
              variant="filled"
              fullWidth
              onChange={(e) => setNoButton(e.target.value)}
            />
            <Tooltip title="Defualt: No">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Order Status"
              value={orderStat}
              variant="filled"
              fullWidth
              onChange={(e) => setOrderStat(e.target.value)}
            />
            <Tooltip title="Defualt: Order Status">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Quantity"
              value={quantity}
              variant="filled"
              fullWidth
              onChange={(e) => setQuantity(e.target.value)}
            />
            <Tooltip title="Defualt: Quantity">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="No Orders"
              value={noOrder}
              variant="filled"
              fullWidth
              onChange={(e) => setNoOrder(e.target.value)}
            />
            <Tooltip title="Defualt: No Orders Yet">
              <InfoIcon />
            </Tooltip>
          </Grid>
        </Grid>
        <Typography variant="h6" className="mb-2">
          About Page Translation
        </Typography>
        <Grid Grid container spacing={2} className="mb-4">
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="About Page Title"
              value={aboutTitle}
              variant="filled"
              fullWidth
              onChange={(e) => setAboutTitle(e.target.value)}
            />
            <Tooltip title="Defualt: About us">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Follow Us Title"
              value={followTitle}
              variant="filled"
              fullWidth
              onChange={(e) => setFollowTitle(e.target.value)}
            />
            <Tooltip title="Defualt: Follow us on social media">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="No About Information"
              value={noAbout}
              variant="filled"
              fullWidth
              onChange={(e) => setNoAbout(e.target.value)}
            />
            <Tooltip title="Defualt: Follow us on social media">
              <InfoIcon />
            </Tooltip>
            <Tooltip title="Defualt: No Information">
              <InfoIcon />
            </Tooltip>
          </Grid>

        </Grid>
        <Typography variant="h6" className="mb-2">
          Search Page Translation
        </Typography>
        <Grid Grid container spacing={2} className="mb-4">
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="No Result "
              value={nosearch}
              variant="filled"
              fullWidth
              onChange={(e) => setNoSearch(e.target.value)}
            />
            <Tooltip title="Defualt: No Result">
              <InfoIcon />
            </Tooltip>
          </Grid>
        </Grid>
        <Typography variant="h6" className="mb-2">
          Messages Translation
        </Typography>
        <Grid Grid container spacing={2} className="mb-4">
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Message Wait "
              value={waitMess}
              variant="filled"
              fullWidth
              onChange={(e) => setWaitMess(e.target.value)}
            />
            <Tooltip title="Defualt: Please, wait ...">
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Success Message "
              value={successMess}
              variant="filled"
              fullWidth
              onChange={(e) => setSuccessMess(e.target.value)}
            />
            <Tooltip title="Defualt: Your request has been sent successfully">
              <InfoIcon />
            </Tooltip>
          </Grid>
        </Grid>
        <Typography variant="h6" className="mb-2">
          Button Translation
        </Typography>
        <Grid Grid container spacing={2} className="mb-4">
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="filled-basic"
              label="Submit Button"
              value={btnSub}
              variant="filled"
              fullWidth
              onChange={(e) => setBtnSub(e.target.value)}
            />
            <Tooltip title="Defualt: Submit">
              <InfoIcon />
            </Tooltip>
          </Grid>
        </Grid>
        <Button variant="contained" onClick={CreateLangForm} className="btn-add">
          Add Language
        </Button>
      </Container>
    </>
  );
};

export default AddLanguage;
