import React from 'react';
import { DatePicker, Space } from 'antd';

const MonthPicker = ({setSelectedMonthYear}) => {
    const onChange = (date, dateString) => {
        let Data = {}
        if(dateString !== ""){
            Data = {
                month : parseInt( dateString.split("-")[1]),
                year : dateString.split("-")[0]
            }
        }else {
            Data = {} 
        }
        setSelectedMonthYear(Data)
    };
    return(
        <Space direction="vertical">
            <DatePicker onChange={onChange} picker="month" />
        </Space>
    ) 
}
export default MonthPicker;