import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  CircularProgress,
  Alert,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material';
import { useState } from 'react';
import axios from 'src/api/axios';
import { ITEMS, PACKAGES } from 'src/constants/url';

const DeletePackage = ({ reload, setReload, id }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage(false);
  };

  const DeleteItemSubmit = async () => {
    setLoading(true);
    await axios
      .delete(`${PACKAGES}/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setReload(!reload);
          }, 1500);
          setOpen(false);
          setMessage(true);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    setLoading(false);
  };
  return (
    <>
      <Button variant="contained" onClick={handleClickOpen} className="bg-danger border-0 text-white mt-2">
        <DeleteOutlineOutlinedIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure to delete the package?</DialogContentText>
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className="bg-danger text-white">
              {' '}
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="bg-danger text-white fs-6" onClick={DeleteItemSubmit}>
              Yes
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={message} autoHideDuration={2000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' , overflowX : "hidden"  }}
        >
          success! Item Deleted Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default DeletePackage;
