import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ItemSelect from '../ItemSelect';
import { useEffect, useState } from 'react';
import axios from 'src/api/axios';
import {
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  //   DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
} from '@mui/material';
import ImageUploading from 'react-images-uploading';
import { ADD_LANG, CURRENCY, ITEMS, PACKAGES, STORAGE } from 'src/constants/url';
import CategorySection from '../CategorySection';

const UpdateCurrency = ({ reload, setReload, id, i }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [error, setError] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const [empty, setEmpty] = useState('');
  const [currency , setCurrency] = useState(i.currency);
  const [exchangeRate ,  setExchangeRate] = useState(i.exchange_rate)

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UpdateItemForm = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append(`currency`, currency);
    formData.append('exchange_rate', exchangeRate);

    axios
      .post(`${CURRENCY}/${id}?_method=PUT`, formData, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((res) => {
        setMessage(true);
        setTimeout(() => {
          setReload(!reload);
        }, 1500);
        setOpen(false);
        setError(false);
      })

      .catch((error) => {
        setError(true);
        showErrorAlert(error.response.data.message);
        setEmpty('please add currency then try to update it');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Button style={{width : "50px"}} variant="outlined" onClick={handleClickOpen} className="btn-bg border-0 text-white mt-2">
        <ModeEditOutlineOutlinedIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update Currency</DialogTitle>
        <DialogContent>
          <>
            {error ? (
              <Alert severity="error" className="mb-4">
                <AlertTitle>Error</AlertTitle>
                <p>{erroralert}</p>
                {category_section != null ? '' : empty}
              </Alert>
            ) : (
              ''
            )}
            {
              <>
                {/* {
                  <TextField
                    id="filled-basic"
                    label="Currency Name"
                    value={currency}
                    variant="filled"
                    fullWidth
                    onChange={(e) => setCurrency(e.target.value)}
                    className="mb-2"
                  />
                } */}
              </>
            }


            <TextField
              id="filled-basic"
              label="Exchange Rate"
              value={exchangeRate}
              type="number"
              variant="filled"
              fullWidth
              onChange={(e) => setExchangeRate(e.target.value)}
              className="mb-2"
            />

          </>
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={UpdateItemForm}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' , overflowX : "hidden"  }}

        >
          success! Currncey Updated Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default UpdateCurrency;
