import ReactDOM from 'react-dom/client';


import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// 
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthProvider';
// import { disableReactDevTools } from '@fvilers/disable-react-devtools';

// if (process.env.NODE_ENV === 'production') {
    // disableReactDevTools();
//   }
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <AuthProvider>
        <HelmetProvider >
            <BrowserRouter >
                <Routes>
                    <Route path="/*" element={<App />} />
                </Routes>
            </BrowserRouter >
        </HelmetProvider >
        </AuthProvider>
);

serviceWorker.unregister();

reportWebVitals();
