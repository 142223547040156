import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import {
  Typography,
  Container,
  Grid,
  Card,
  CardMedia,
  Stack,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Button,
} from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import OrderCard from './OrderCard';
import axios from 'src/api/axios';
import { BaseUrl, DeleteOrder, MergedNotifications, TABLES , UpdateStatusComplete, UpdateStatusPinding, UpdateStatusProgress } from 'src/constants/url';
import { object } from 'prop-types';
import { Spinner } from 'react-bootstrap';
import { onMessageListener } from 'src/firebase';
import MerNotifica from '../MerNotifica';
import MerNotificaMerge from '../MerNotificaMerge';
// import './TablesPage.css'; // Import your custom CSS file
export const MySvg = () => (
  <div className="all-togather note-svg" style={{position : "absolute"}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 9 9" fill="none">
            <path d="M5.9 4.0625H7.37778C7.59559 4.0625 7.70448 4.0625 7.78767 4.01481C7.86086 3.97286 7.92032 3.90596 7.95761 3.82363C8 3.73004 8 3.60752 8 3.3625V1.7C8 1.45498 8 1.33246 7.95761 1.23888C7.92032 1.15656 7.86086 1.08963 7.78767 1.04768C7.70448 1 7.59559 1 7.37778 1H5.9C5.68218 1 5.57329 1 5.49011 1.04768C5.41692 1.08963 5.35746 1.15656 5.32017 1.23888C5.27778 1.33246 5.27778 1.45498 5.27778 1.7V3.3625C5.27778 3.60752 5.27778 3.73004 5.32017 3.82363C5.35746 3.90596 5.41692 3.97286 5.49011 4.01481C5.57329 4.0625 5.68218 4.0625 5.9 4.0625Z" fill="white"/>
            <path d="M6.3375 8H7.0375C7.25532 8 7.36421 8 7.44739 7.95231C7.52058 7.91036 7.58004 7.84346 7.61733 7.76113C7.65972 7.66754 7.65972 7.54504 7.65972 7.3V6.5125C7.65972 6.26746 7.65972 6.14496 7.61733 6.05137C7.58004 5.96904 7.52058 5.90214 7.44739 5.86019C7.36421 5.8125 7.25532 5.8125 7.0375 5.8125H6.3375C6.11968 5.8125 6.01079 5.8125 5.92761 5.86019C5.85442 5.90214 5.79496 5.96904 5.75767 6.05137C5.71528 6.14496 5.71528 6.26746 5.71528 6.5125V7.3C5.71528 7.54504 5.71528 7.66754 5.75767 7.76113C5.79496 7.84346 5.85442 7.91036 5.92761 7.95231C6.01079 8 6.11968 8 6.3375 8Z" fill="white"/>
            <path d="M2.55556 1V8M2.55556 1L4.11111 2.75M2.55556 1L1 2.75M5.9 4.0625H7.37778C7.59559 4.0625 7.70448 4.0625 7.78767 4.01481C7.86086 3.97286 7.92032 3.90596 7.95761 3.82362C8 3.73004 8 3.60752 8 3.3625V1.7C8 1.45498 8 1.33247 7.95761 1.23888C7.92032 1.15656 7.86086 1.08963 7.78767 1.04768C7.70448 1 7.59559 1 7.37778 1H5.9C5.68218 1 5.57329 1 5.49011 1.04768C5.41692 1.08963 5.35746 1.15656 5.32017 1.23888C5.27778 1.33247 5.27778 1.45498 5.27778 1.7V3.3625C5.27778 3.60752 5.27778 3.73004 5.32017 3.82362C5.35746 3.90596 5.41692 3.97286 5.49011 4.01481C5.57329 4.0625 5.68218 4.0625 5.9 4.0625ZM6.3375 8H7.0375C7.25532 8 7.36421 8 7.44739 7.95231C7.52058 7.91036 7.58004 7.84346 7.61733 7.76113C7.65972 7.66754 7.65972 7.54504 7.65972 7.3V6.5125C7.65972 6.26746 7.65972 6.14496 7.61733 6.05137C7.58004 5.96904 7.52058 5.90214 7.44739 5.86019C7.36421 5.8125 7.25532 5.8125 7.0375 5.8125H6.3375C6.11968 5.8125 6.01079 5.8125 5.92761 5.86019C5.85442 5.90214 5.79496 5.96904 5.75767 6.05137C5.71528 6.14496 5.71528 6.26746 5.71528 6.5125V7.3C5.71528 7.54504 5.71528 7.66754 5.75767 7.76113C5.79496 7.84346 5.85442 7.91036 5.92761 7.95231C6.01079 8 6.11968 8 6.3375 8Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>
);
const Order = ({FetchNotifications}) => {
  let roles = localStorage.getItem("role_name");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTab1, setSelectedTab1] = useState(0);
  const [showTable, setShowTable] = useState(false); // State to control table visibility
  const [openNote, setOpenNote] = useState(false);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleChange1 = (event, newValue) => {
    setSelectedTab1(newValue);
  };
    const handleShowTable = () => {
    setShowTable(!showTable); // Toggle the showTable state
  };
  const [activeSvg, setActiveSvg] = useState('svg2');

  // Function to handle SVG click and toggle active state
  const handleSvgClick = (svgId) => {
    setActiveSvg(svgId === activeSvg ? null : svgId);
  };


  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const [totalTables , setTotalTables] = useState(0)
  const fetchTableNumber = async()=>{
    await axios.get(TABLES , {
      headers : {
        Accept: 'application/json',
      }
    }).then(res => {
      setTotalTables(res.data.data.tablesNumber);
    })
  }
  useEffect(()=>{
    fetchTableNumber();
  },[])
  const [tablesHaveOrders , setTablesHaveOrders] = useState([""])
  if(localStorage.getItem("index_table")=== null || localStorage.getItem("index_table") === NaN){
    localStorage.setItem("index_table","1")
  }
  const [index, setIndex] = useState(parseInt(window.localStorage.getItem("index_table")));
  window.localStorage.setItem("index_table" , index)
  const [total , setTotal] = useState(0)
  const [orderStatus , setOrderStatus] = useState("")
  const toggleOrderStatus = () => {
    // Check the current status and toggle it
    if (orderStatus === 'pending') {
      setOrderStatus('in_progress');
    } else if(orderStatus === "in_progress"){
      setOrderStatus('pending');
    }
  };
  const [data , setData] =useState([])
  const [lastData , setLastDAta] = useState([])
  const [orderNumber , setOrderNumber] = useState(0)
  const [orderTime , setOrderTime] = useState("")
  const [loadData ,setLoadData] = useState(true)
  const [classOrder , setClassOrder] = useState("card-order")
  const [classContainerOrder , setClassContainerOrder] = useState("cards-container")
  const [categoriesName , setCategoriesName] = useState([])
  const toggleCardItems = ()=> {
    setClassOrder("card-order");
    setClassContainerOrder("cards-container");
  }
  const toggleCardList = ()=> {
    setClassOrder("card-order-list");
    setClassContainerOrder("cards-container-list");
  }
  const fetchOrders = async (index) => {
    try {
      const response = await axios.get(`${BaseUrl}/api/tableOrders/${index}`, {
        headers: {
          Accept: 'application/json',
        },
      });
      if (response.status === 200) {
        setCategoriesName(response.data.data.categories)
        setOrderStatus(response.data.data === null ? "" : response.data.data.tableStatus)
        const data = Object.values(response.data.data === null ? "" : response.data.data.items);
        setOrderNumber(response.data.data === null ? "" : response.data.data.billNum)
        setOrderTime( response.data.data === null ? "" : response.data.data.time)
        const arr = Object.values(Object.values(data));
        setLastDAta(arr);
        setLoadData(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const [doneLoading ,setDoneLoading] = useState(false)
  const [doneLoadingDelete ,setDoneLoadingDelete] = useState(false)
  const StatusDone = async()=>{
    await axios.post(`${UpdateStatusComplete}/${index}`,{},{
      headers: {
        Accept: 'application/json',
      }
    }).then(res => {
      (res)
      if(res.status === 200){
        setDoneLoading(false)
        fetchOrders(index)
      }
      })
  }
  
  useEffect(() => {
    fetchOrders(index);
  }, [index , loadData , doneLoading , doneLoadingDelete]);
  const slide = (offset) => {
    setIndex(index + offset)
    window.localStorage.setItem("index_table" , parseInt(index))
    setLoadData(true)
    setOrderNumber(null)
    setItemsDetails([[],"",""])
  }
  const [activeCard, setActiveCard] = useState(null);
  const [itemDetails , setItemsDetails] = useState([[],"",""])
  const handleCardClick = (notes) => {
    setItemsDetails(notes)
};
  const [selectedCategory, setSelectedCategory] = useState("all"); 
  const handleTabClick = (category) => {
    setSelectedCategory(category);
  };
  const [loadstatus , setLoadStatus] = useState(false);
  const UpdateStatusFunc = async()=>{
     if(orderStatus.toLocaleLowerCase() === "pending"){
      setLoadStatus(true)
       await axios.post(`${UpdateStatusProgress}/${index}`,{},{
         headers : {
           Accept: 'application/json',
      }
    }).then(res => {
      if(res.status === 200) {
        setLoadStatus(false)
        toggleOrderStatus()
      }
    })
  }
  }
  const [notiArray, setNotiArray] = useState([]);
  const FetchMergeNotification = async () => {
    await axios.post(MergedNotifications , {
      table_id : parseInt(index)
    },{
      headers : {
        Accept: 'application/json',
      }
    }).then(res =>{
      if(res.status === 200) {
        setNotiArray(res.data.data)
      }
    })
  }
  useEffect(()=>{
    FetchMergeNotification()
  },[index])
  onMessageListener()
    .then ((payload) => {
      fetchOrders(index)
      FetchMergeNotification()
      FetchNotifications()
      if(payload.data.type === "Merge") {
        return payload
      }else {
        return null
      }
    })
    const DeleteOrderFunc = async()=>{
      await axios.post(DeleteOrder,{bill_number : orderNumber},{
        headers : {
          Accept: 'application/json',
        }
      }).then(res=> {
        if(res.status === 200) {
        setDoneLoadingDelete(false)
        }
      })
    }
   
  return (
<>
      <Helmet>
        <title>Tables Page</title>
      </Helmet>
      <Container  style={{padding:"5px"}}>
      <MerNotificaMerge noti={notiArray} orderNumber={orderNumber} fetchOrders={fetchOrders}/>
      <Grid item container style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', flexWrap: "wrap", gap: "10px" }}>
        <div className="nameTable">
          <div>
            <div className="counter1 d-flex align-items-center">
              <button
                className="paginate-left m-2 bb"
                onClick={() => slide(-1)}
                disabled={index === 0}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                  <path d="M9 16L3 9L9 2" stroke="#CD974F" strokeWidth="4" strokeLinecap="round"/>
                </svg>
              </button >
              Table  <span className='ms-3' style={{ color: "#cda34ff4" , display :"block" }}>{index}</span>
              <button
                className="paginate-right m-2 bb"
                onClick={() => slide(1)}
                disabled={index === totalTables}
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                <path d="M2 16L8 9L2 2" stroke="#CD974F" strokeWidth="4" strokeLinecap="round"/>
              </svg>
              </button>
            </div>
          </div>

        </div>
        <div style={{userSelect : "none"}} className={`statusTable ${orderStatus}`} onClick={()=>{if(roles === "Chef"){UpdateStatusFunc()}}}>
          {
          loadstatus ? <Spinner animation="border" variant="light" /> : orderStatus.replace("_"," ")
          }
        </div>
      </Grid>

      <Container>

        <Grid container   >

          <Grid item xs={12} lg={8} xl={8} md={8} sm={12} style={{backgroundColor:" white",border: "1px solid #A77C2736",
            padding: "10px",
            marginTop: "26px",
            borderRadius:" 14px"}}>
            <Grid item xs={12} sm={12}>
              <div className="newOrdersNumber" style={{textAlign: "left", marginTop: "20px", marginLeft: "2px", flexWrap: "wrap", display: 'flex', justifyContent: "space-between" , height : "59px"}}>
                <div style={{display : "flex" ,justifyContent : "space-between" ,width : "100%" , padding : "0 30px"}} > 
                  <p className={orderTime === "" ? "d-none" : 'order'} style={{
                  color: "#CDA34FF4",
                  fontSize: "22px",
                  fontWeight: "600",
                  lineHeight: "33px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  fontFamily: "Poppins"
                }}>
                  Order #{orderNumber} 
                </p>
                  <p className={orderTime === "" ? "d-none" : 'order'}  style={{
                  color: "#CDA34FF4",
                  fontSize: "22px",
                  fontWeight: "600",
                  lineHeight: "33px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  fontFamily: "Poppins"
                }}>
                  Ordered at {orderTime.slice(11,19)}
                </p>
                </div>
              </div>
              <hr style={{  marginRight: "14px" , background: "#af7500"}}/>
            </Grid>

            <div className="tabs2  tabs3" style={{ display: 'flex', justifyContent: 'space-between' , alignItems : "center"}}>
              <Tabs
                value={selectedCategory}
                className="tabs2"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                style={{ maxWidth: "710px", hight: '39px', width: "auto" }}
              >
                <Tab className={selectedCategory === "all" ? 'btnSlider active-tab' : 'btnSlider'} label={"All"} value={"all"} onClick={() => handleTabClick("all")}/>
                {
                  categoriesName.map((e,i) =>{
                    return(
                      <Tab className={selectedCategory === e ? 'btnSlider active-tab' : 'btnSlider'} key={i} label={e} onClick={() => handleTabClick(e)} value={e}/>
                    )
                  })
                }
              </Tabs>
              <div className="tab-buttons">
                <Button id="svg1"  onClick={() => {handleSvgClick('svg1');toggleCardList()}} className={activeSvg === 'svg1' ? 'activeSVG tab-button' : 'tab-button'} variant="contained" color="primary" >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                  <g  clipPath="url(#clip0_780_17585)">
                  <path fillRule="evenodd" clipRule="evenodd" d="M18.7097 13.1774H6.45161C5.73935 13.1774 5.16129 13.7266 5.16129 14.4032C5.16129 15.0799 5.73935 15.629 6.45161 15.629H18.7097C19.4219 15.629 20 15.0799 20 14.4032C20 13.7266 19.4219 13.1774 18.7097 13.1774ZM18.7097 8.27419H6.45161C5.73935 8.27419 5.16129 8.82335 5.16129 9.49999C5.16129 10.1766 5.73935 10.7258 6.45161 10.7258H18.7097C19.4219 10.7258 20 10.1766 20 9.49999C20 8.82335 19.4219 8.27419 18.7097 8.27419ZM6.45161 5.82257H18.7097C19.4219 5.82257 20 5.27341 20 4.59677C20 3.92012 19.4219 3.37096 18.7097 3.37096H6.45161C5.73935 3.37096 5.16129 3.92012 5.16129 4.59677C5.16129 5.27341 5.73935 5.82257 6.45161 5.82257ZM1.93548 12.5645C0.866452 12.5645 0 13.3876 0 14.4032C0 15.4188 0.866452 16.2419 1.93548 16.2419C3.00452 16.2419 3.87097 15.4188 3.87097 14.4032C3.87097 13.3876 3.00452 12.5645 1.93548 12.5645ZM1.93548 7.66128C0.866452 7.66128 0 8.48441 0 9.49999C0 10.5156 0.866452 11.3387 1.93548 11.3387C3.00452 11.3387 3.87097 10.5156 3.87097 9.49999C3.87097 8.48441 3.00452 7.66128 1.93548 7.66128ZM1.93548 2.75806C0.866452 2.75806 0 3.58119 0 4.59677C0 5.61235 0.866452 6.43548 1.93548 6.43548C3.00452 6.43548 3.87097 5.61235 3.87097 4.59677C3.87097 3.58119 3.00452 2.75806 1.93548 2.75806Z" fill="#CDA34F"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_780_17585">
                  <rect width="20" height="19" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                </Button>
                <Button id="svg2" onClick={() => {handleSvgClick('svg2');toggleCardItems()}} className={activeSvg === 'svg2' ? 'activeSVG tab-button' : 'tab-button'} variant="contained" color="secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M12.8571 0H10.7143C9.53089 0 8.57143 0.959464 8.57143 2.14286V4.28571C8.57143 5.46911 9.53089 6.42857 10.7143 6.42857H12.8571C14.0405 6.42857 15 5.46911 15 4.28571V2.14286C15 0.959464 14.0405 0 12.8571 0ZM12.8571 8.57143H10.7143C9.53089 8.57143 8.57143 9.53089 8.57143 10.7143V12.8571C8.57143 14.0405 9.53089 15 10.7143 15H12.8571C14.0405 15 15 14.0405 15 12.8571V10.7143C15 9.53089 14.0405 8.57143 12.8571 8.57143ZM4.28571 8.57143H2.14286C0.959464 8.57143 0 9.53089 0 10.7143V12.8571C0 14.0405 0.959464 15 2.14286 15H4.28571C5.46911 15 6.42857 14.0405 6.42857 12.8571V10.7143C6.42857 9.53089 5.46911 8.57143 4.28571 8.57143ZM4.28571 0H2.14286C0.959464 0 0 0.959464 0 2.14286V4.28571C0 5.46911 0.959464 6.42857 2.14286 6.42857H4.28571C5.46911 6.42857 6.42857 5.46911 6.42857 4.28571V2.14286C6.42857 0.959464 5.46911 0 4.28571 0Z" fill="#CDA34F" fillOpacity="0.958352"/>
                </svg>
                </Button>
              </div>
            </div>
            <Grid item container className="tablecard" spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
              {showTable ? ( <></>
              ) : (

                <Grid item container xs={12} lg={12} xl={12} md={12} sm={12} className="tablecard" spacing={2} style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                <>
                <div className={classContainerOrder} style={{
                  display : "flex",
                  justifyContent : "center",
                  alignItems : "center",
                  columnGap : "45px",
                  rowGap : "25px",
                  flexWrap : "wrap",
                  padding : "10px 0 ",
                  minHeight : "180px",
                  width : "95%"
                }}>
                  <p className={classOrder === "card-order-list" ? "list-order" : "d-none"}><span>Item</span><span>Quantity</span><span>Notes</span><span>Details</span></p>
                  { loadData ? <Spinner animation="border" variant="warning" /> : lastData.length === 0 ? <p>There's No Orderes Yet</p> : 
                        Object.values(lastData).map((card)=>{
                          if (selectedCategory === 'all' || card.category === selectedCategory){
                            return (
                              <OrderCard classOrder={classOrder} data={card} key={card.name} setActiveCard={setActiveCard} activeCard={activeCard} onCardClick={handleCardClick}/>
                              )
                            }
                            return null ;
                          })
                      }
                      </div>
                </>
                </Grid>
                )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={4} xl={4} md={4} style={{ marginTop: "20px" , position:"relative" , minHeight : "531px" }}>
            <div className="notes" style={{
              padding: "10px",
              borderRadius: "16px",
            }} >

              <div  style={{display:"flex",justifyContent:"space-between",padding:"10px",paddingBottom:"0px"}}>
              <p className="notes-title">{itemDetails[1]}</p>
                <p className='total'>TOTAL  : <span className="notes-title number-title">{itemDetails[2] === null ? 0 : itemDetails[2]}</span></p>
              </div>
              <hr style={{margin:'0px', background: "#af7500"}}/>
              <div  style={{backgroundColor: "white" ,  padding: "10px",paddingBottom:"18px" , height:"350px",overflowY : "auto",
              borderRadius: "16px"}}>
              {/* <Typography variant="h6" className="notes-title">
                <img src={note} className="" style={{ width: '14px', height: '14px', marginRight: '20px' }} />
                Note{' '}
              </Typography>{' '} */}              

              { itemDetails[0].length === 0 ? <p className='there-no'>There's No Notes ...</p> :
                itemDetails[0].map((note,i) =>{
                  // console.log(note.indexOf("allTogether"))
                  if(note.indexOf("allTogether") !== -1) {
                    const not = note.split("allTogether")
                    return (
                    <div key={i} style={{display : "flex" , alignItems : "center"}} className="note-content">{not[0].replace(", '", "")}<MySvg/></div>
                    )
                  }else {
                    return(
                      <div key={i} className="note-content">{note}</div>
                      )
                    }
                })
              }
              </div>
              <div className="note2">
                {/* <div className="note-content2"> All come together. </div> */}
                <div style={{ 
                  marginTop: '20px',
                  position: "absolute",
                  bottom : "-0px",
                  left : "15px",
                  display : "flex",
                  width : "100%"
                  }}>
                  <div className="grid-container">
                  <span  className={(orderStatus.toLocaleLowerCase() === "busy_table"|| lastData.length === 0 || roles === "Waiter") ? 'delete-button-order d-none' : "delete-button-order"} onClick={()=>{DeleteOrderFunc(); setDoneLoadingDelete(true);FetchMergeNotification()}}>{doneLoadingDelete ? <Spinner animation="border" variant="light" /> : "Delete Order"}</span>
                  <span className={orderStatus.toLocaleLowerCase() === "in_progress" && roles === "Chef" ? 'complete-order' : "complete-order  d-none" } onClick={()=>{StatusDone(); setDoneLoading(true);FetchMergeNotification()}}>{doneLoading ? <Spinner animation="border" variant="light" /> : "Complete Order"}</span>
                </div>
              </div>
              </div>
            </div>{' '}
          </Grid>
        </Grid>
      </Container>
      </Container>
      </>
  );
};

export default Order;