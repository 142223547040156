import { Helmet } from 'react-helmet-async';
// @mui
import { Typography } from '@mui/material';
// components
import Logo from '../components/logo/Logo';
// sections
import { LoginForm } from '../sections/auth/login';


export default function LoginPage({FetchNotifications}) {
  return (
    <>
      <Helmet>
        <title> Login | Fenice Palas </title>
      </Helmet>
      <div className="bg-login">
        <div className="container">
          <div className='row align-items-center custom-log-in'>
            <div className='col-lg-5'>
              <Logo />
              <Typography variant="h2" style={{fontFamily : "Great Vibes" , marginBottom : "10px" }}>
                Welcome to Fenice Palas
              </Typography>
            </div>
            <div className='col-lg-7'>
            <div className='box-form' style={{borderRadius : "10px"}}>
            <LoginForm FetchNotifications={FetchNotifications}/>
            </div>
            </div>
            </div>
        </div>
      </div>
    </>
  );
}
