import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useEffect, useState } from 'react';
import axios from 'src/api/axios';
import {
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  Grid,
} from '@mui/material';
import { ABOUT, DELETE_ABOUT_IMAGE, STORAGE } from 'src/constants/url';
import ImageUploading from 'react-images-uploading';
import ItemSelect from '../ItemSelect';
import '../custom.css'
import { Height } from '@mui/icons-material';

const UpdateAbout = ({ reload, setReload, i }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [images, setImages] = useState([]);
  const [titleEn, setTitleEn] = useState(i.title);
  const [bioEn, setbioEn] = useState(i.bio);
  const [email , setEmail] = useState(i.email);
  const [openTime, setOpenTime] = useState(i.open);
  const [address, setAddress] = useState(i.address);
  const [tel, setTel] = useState(i.tel);
  const [facebook, setFacebook] = useState(i.facebook);
  const [instagram, setInstagram] = useState(i.instagram);
  const [youtube, setYoutube] = useState(i.youtube);
  const [showImages, setshowImages] = useState(i.images);

  useEffect(() => {
    setTitleEn(i.title);
    setbioEn(i.bio);
    setAddress(i.address);
    setEmail(i.email);
    setOpenTime(i.open);
    setTel(i.tel);
    setFacebook(i.facebook);
    setInstagram(i.instagram);
    setYoutube(i.youtube);
    setshowImages(i.images);
  }, [i]);

  const maxNumber = 70;
  const onChange = (imageList, addUpdateIndex) => {
      setImages(imageList[0].file);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };
  const UpdateAboutItem = async () => {
    setLoading(true);
    const formData = new FormData();

    formData.append('address', address);
    formData.append('bio', bioEn);
    formData.append('email',email);
    formData.append('facebook', facebook);
    formData.append('instagram', instagram);
    formData.append('youtube', youtube);
    formData.append('images[]', images);
    formData.append('open',openTime)
    formData.append('title', titleEn);
    formData.append('tel', tel);

    await axios
      .post(`${ABOUT}/1?_method=PUT`, formData, {
        headers: {
          'Content-Type': `multipart/form-data`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        console.log(response.request.response)
        if (response.status === 200) {
          setMessage(true);
          setTimeout(() => {
            setReload(!reload);
          }, 1500);
          setOpen(false);
        }
      })
      .catch(error => {
        // Handle errors
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          console.log('Response status:', error.response.status);
          console.log('Response data:', error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Request was made but no response received:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error setting up the request:', error.message);
        }
        console.log('Error config:', error.config);
      })
      .finally(() => {
        setLoading(false);
        setImages([]);
      });
  };

  const deleteImage = (id) => {
    document.body.style.cursor = 'wait';
    axios
      .delete(`${DELETE_ABOUT_IMAGE}${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(() => {})
      .catch((error) => {
        console.log(error.response.data.message);
      })
      .finally(() => {
        setReload(!reload);
        setOpen(false);
        document.body.style.cursor = 'default';
      });
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen} className="btn-bg border-0 text-white">
        <ModeEditOutlineOutlinedIcon /> Update information
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update information</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={12} className="mb-2">
              <ImageUploading value={images} onChange={onChange} dataURLKey="data_url" maxNumber={maxNumber} multiple>
                {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                  <div className="upload__image-wrapper position-relative mb-3">
                    <button
                      className="btn-add w-100 d-block text-white border-0 py-2"
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                      type="button"
                    >
                      upload Image
                    </button>
                    {imageList.length > 0
                      ? imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image['data_url']} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                onClick={() => onImageRemove(index)}
                                className="bg-danger border-0 text-white position-absolute bottom-0 end-0"
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))
                      : null}
                    {showImages.length > 0 &&
                      showImages.map((item, index) => (
                        <div key={index} className="position-relative mt-2 mb-2">
                          <img src={`${STORAGE}/${item.image}`} width="100%" />
                          <button
                            onClick={() => deleteImage(item.id)}
                            className="bg-danger border-0 text-white position-absolute bottom-0 end-0"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                  </div>
                )}
              </ImageUploading>
            </Grid>
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-basic"
                label="Item Title"
                value={titleEn}
                variant="filled"
                onChange={(e) => setTitleEn(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-multiline-static"
                label="Item Bio"
                multiline
                value={bioEn}
                variant="filled"
                fullWidth
                onChange={(e) => setbioEn(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-multiline-static"
                label="Email Adress"
                multiline
                value={email}
                variant="filled"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid> 
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-multiline-static"
                label="Open At "
                multiline
                value={openTime}
                variant="filled"
                fullWidth
                onChange={(e) => setOpenTime(e.target.value)}
              />
            </Grid> 
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-basic"
                label="Item Address"
                value={address}
                variant="filled"
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-basic"
                label="Item Telephone"
                value={tel}
                variant="filled"
                onChange={(e) => setTel(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-basic"
                label="Item Facebook"
                value={facebook}
                variant="filled"
                onChange={(e) => setFacebook(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-basic"
                label="Item Instagram"
                value={instagram}
                variant="filled"
                onChange={(e) => setInstagram(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} className="mb-2">
              <TextField
                id="filled-basic"
                label="Item Youtube"
                value={youtube}
                variant="filled"
                onChange={(e) => setYoutube(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={UpdateAboutItem}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' , overflowX : "hidden"  }}

        >
          success! Information Updated Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default UpdateAbout;
