import { useNavigate } from 'react-router-dom';
import './table.css'
import { useState } from 'react';
function TableSvg ({textColor , color , color2, newOrder , tabelNumber , setDeletePopUP , setDeletedTableNumber , setResetPopUP}) {
    const navigate = useNavigate();
    let role_name = localStorage.getItem("role_name");
    const clickTable = () => {
        if(color !== "white"){
            localStorage.setItem("index_table",tabelNumber);
            navigate("/dashboard/orders")
        }
    }
    const deleteFunction = () => {
        setDeletePopUP(1)
        setDeletedTableNumber(tabelNumber)
    }
    return (
        <div className='table-gen'>
    <div className="table-styling" onClick={()=>{clickTable()}}>
    <svg xmlns="http://www.w3.org/2000/svg" width="162" height="162" viewBox="0 0 162 162" fill={color}>
        <g opacity="0.88">
            <path fillRule="evenodd" clipRule="evenodd" d="M79.7333 33.8254C81.5429 33.8254 82.9906 35.3009 82.9906 37.0826V125.558C82.9906 127.367 81.515 128.815 79.7333 128.815C77.9237 128.815 76.476 127.339 76.476 125.558V37.1105C76.476 35.3009 77.9515 33.8532 79.7333 33.8532V33.8254Z" fill={color} stroke={color2} strokeMiterlimit="10"/>
            <path d="M79.7333 129.539C77.5339 129.539 75.7522 127.757 75.7522 125.558V37.1105C75.7522 34.9111 77.5339 33.1294 79.7333 33.1294C81.9326 33.1294 83.7144 34.9111 83.7144 37.1105V125.585C83.7144 127.785 81.9326 129.567 79.7333 129.567V129.539ZM79.7333 34.5214C78.3135 34.5214 77.172 35.6628 77.172 37.0827V125.558C77.172 126.977 78.3135 128.119 79.7333 128.119C81.1531 128.119 82.2946 126.977 82.2946 125.558V37.1105C82.2946 35.6907 81.1531 34.5492 79.7333 34.5492V34.5214Z" fill="#070707" stroke={color2} strokeMiterlimit="10"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M127.256 81.348C127.256 83.1575 125.78 84.6331 123.999 84.6331H35.4958C33.6862 84.6331 32.2385 83.1575 32.2385 81.348C32.2385 79.5384 33.714 78.0907 35.4958 78.0907H123.971C125.78 78.0907 127.228 79.5662 127.228 81.348H127.256Z" fill={color} stroke={color2} strokeMiterlimit="10"/>
            <path d="M123.971 85.3291H35.4958C33.2964 85.3291 31.5147 83.5473 31.5147 81.348C31.5147 79.1486 33.2964 77.3669 35.4958 77.3669H123.971C126.17 77.3669 127.952 79.1486 127.952 81.348C127.952 83.5473 126.17 85.3291 123.971 85.3291ZM35.4958 78.7867C34.076 78.7867 32.9345 79.9281 32.9345 81.348C32.9345 82.7678 34.076 83.9092 35.4958 83.9092H123.971C125.391 83.9092 126.532 82.7678 126.532 81.348C126.532 79.9281 125.391 78.7867 123.971 78.7867H35.4958Z" fill="#070707" stroke={color2} strokeMiterlimit="10"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M79.7333 122.662C102.506 122.662 121.075 104.093 121.075 81.3201C121.075 58.5471 102.506 39.978 79.7333 39.978C56.9603 39.978 38.3911 58.5471 38.3911 81.3201C38.3911 104.093 56.9603 122.662 79.7333 122.662Z" fill={color} stroke={color2} strokeMiterlimit="10"/>
            <path d="M79.7333 123.386C56.5427 123.386 37.6673 104.511 37.6673 81.348C37.6673 58.1852 56.5427 39.282 79.7333 39.282C102.924 39.282 121.799 58.1574 121.799 81.348C121.799 104.539 102.924 123.386 79.7333 123.386ZM79.7333 40.7018C57.3222 40.7018 39.0871 58.9369 39.0871 81.348C39.0871 103.759 57.3222 121.966 79.7333 121.966C102.144 121.966 120.379 103.731 120.379 81.348C120.379 58.9647 102.144 40.7018 79.7333 40.7018Z" fill="#070707" stroke={color2} strokeMiterlimit="10"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M107.907 35.7185L129.4 57.2109C130.875 58.6864 133.297 58.6864 134.773 57.2109L157.045 34.939L130.151 8.07355L107.879 30.3454C106.404 31.8209 106.404 34.243 107.879 35.7185H107.907Z" fill={color} stroke={color2} strokeMiterlimit="10"/>
            <path d="M132.072 59.0483C130.875 59.0483 129.734 58.575 128.871 57.7398L107.378 36.2474C106.515 35.4122 106.07 34.2708 106.07 33.0737C106.07 31.8766 106.543 30.7351 107.378 29.8721L130.151 7.07129L158.019 34.939L135.246 57.7119C134.411 58.575 133.269 59.0204 132.044 59.0204L132.072 59.0483ZM130.151 9.1036L108.38 30.8743C107.796 31.459 107.49 32.2385 107.49 33.0459C107.49 33.8811 107.796 34.6606 108.38 35.2174L129.873 56.7097C131.042 57.879 133.074 57.879 134.244 56.7097L156.014 34.939L130.151 9.07576V9.1036Z" fill="#070707" stroke={color2} strokeMiterlimit="10"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M134.745 6.98776L158.13 30.3732C159.606 31.8487 161.081 34.6327 158.437 35.4401L155.736 36.2753L128.843 9.38198L129.678 6.68152C130.569 3.7305 133.269 5.51224 134.745 6.98776Z" fill={color} stroke={color2} strokeMiterlimit="10"/>
            <path d="M155.513 37.0548L128.063 9.60473L129.01 6.48667C129.567 4.67708 130.736 4.39868 131.404 4.39868C132.991 4.39868 134.633 5.87419 135.246 6.51451L158.631 29.9C159.745 31.0136 160.914 32.851 160.524 34.3822C160.385 34.9668 159.94 35.7742 158.631 36.1361L155.513 37.0826V37.0548ZM129.65 9.18713L155.931 35.4679L158.242 34.7719C159.021 34.5214 159.132 34.1316 159.16 34.0203C159.355 33.2964 158.687 31.9601 157.629 30.9022L134.244 7.4889C133.214 6.45883 132.1 5.79067 131.404 5.79067C131.209 5.79067 130.708 5.79067 130.374 6.87643L129.678 9.18713H129.65Z" fill="#070707" stroke={color2} strokeMiterlimit="10"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M54.0928 126.281L32.6005 104.789C31.1249 103.314 28.7029 103.314 27.2274 104.789L4.95549 127.061L31.8209 153.926L54.0928 131.655C55.5683 130.179 55.5683 127.757 54.0928 126.281Z" fill={color} stroke={color2} strokeMiterlimit="10"/>
            <path d="M31.8209 154.929L3.95326 127.033L26.7262 104.26C28.4245 102.562 31.4033 102.562 33.1016 104.26L54.5939 125.753C56.3478 127.506 56.3478 130.374 54.5939 132.128L31.8209 154.901V154.929ZM5.95773 127.033L31.8209 152.896L53.5917 131.126C54.7888 129.929 54.7888 127.952 53.5917 126.755L32.0993 105.262C30.9301 104.093 28.8978 104.093 27.7285 105.262L5.95773 127.033Z" fill="#070707" stroke={color2} strokeMiterlimit="10"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.2552 155.012L3.86974 131.627C2.39423 130.151 0.918715 127.367 3.5635 126.56L6.26396 125.725L33.1294 152.59L32.3221 155.291C31.4312 158.242 28.7307 156.46 27.2552 154.984V155.012Z" fill={color} stroke={color2} strokeMiterlimit="10"/>
            <path d="M30.596 157.629C29.0091 157.629 27.3666 156.154 26.7541 155.513L3.36862 132.128C2.25503 131.014 1.08575 129.177 1.47551 127.673C1.61471 127.089 2.06015 126.282 3.36862 125.892L6.48668 124.945L33.9646 152.423L33.018 155.541C32.4613 157.351 31.292 157.629 30.6238 157.629H30.596ZM6.06909 126.532L3.75838 127.228C2.95102 127.479 2.8675 127.868 2.83966 127.98C2.64479 128.704 3.31294 130.04 4.37086 131.098L27.7563 154.483C28.7864 155.513 29.9 156.181 30.596 156.181C30.7909 156.181 31.292 156.181 31.6261 155.096L32.3221 152.785L6.06909 126.532Z" fill="#070707" stroke={color2} strokeMiterlimit="10"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M126.282 107.907L104.789 129.4C103.314 130.875 103.314 133.297 104.789 134.773L127.061 157.044L153.926 130.179L131.655 107.907C130.179 106.432 127.757 106.432 126.282 107.907Z" fill={color} stroke={color2} strokeMiterlimit="10"/>
            <path d="M127.033 158.047L104.26 135.274C102.506 133.52 102.506 130.652 104.26 128.898L125.753 107.406C127.451 105.708 130.43 105.708 132.128 107.406L154.901 130.179L127.033 158.047ZM128.954 107.517C128.119 107.517 127.339 107.851 126.783 108.408L105.29 129.901C104.093 131.098 104.093 133.074 105.29 134.271L127.061 156.042L152.924 130.179L131.153 108.408C130.569 107.824 129.789 107.517 128.982 107.517H128.954Z" fill="#070707" stroke={color2} strokeMiterlimit="10"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M155.012 134.745L131.627 158.13C130.151 159.606 127.367 161.081 126.56 158.437L125.753 155.736L152.618 128.871L155.318 129.706C158.269 130.597 156.488 133.297 155.012 134.773V134.745Z" fill={color} stroke={color2} strokeMiterlimit="10"/>
            <path d="M128.258 160.608C127.59 160.608 126.393 160.357 125.864 158.659L124.917 155.541L152.395 128.063L155.513 129.01C156.905 129.427 157.379 130.235 157.546 130.819C157.991 132.545 156.265 134.494 155.513 135.246L132.128 158.631C131.181 159.578 129.65 160.608 128.286 160.608H128.258ZM126.532 155.931L127.228 158.242C127.479 159.077 127.924 159.188 128.258 159.188C129.01 159.188 130.179 158.548 131.098 157.629L154.483 134.244C155.653 133.074 156.321 131.849 156.154 131.181C156.07 130.847 155.708 130.569 155.096 130.374L152.785 129.678L126.504 155.959L126.532 155.931Z" fill="#070707" stroke={color2} strokeMiterlimit="10"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M35.7185 54.0928L57.2109 32.6005C58.6864 31.125 58.6864 28.7029 57.2109 27.2274L34.9668 4.95551L8.07355 31.8488L30.3454 54.1207C31.8209 55.5962 34.243 55.5962 35.7185 54.1207V54.0928Z" fill={color} stroke={color2} strokeMiterlimit="10"/>
            <path d="M33.0459 55.9302C31.8488 55.9302 30.7073 55.4569 29.8443 54.6218L7.07132 31.8488L34.9668 3.95325L57.7398 26.7262C58.6029 27.5614 59.0483 28.7029 59.0483 29.9C59.0483 31.0971 58.575 32.2385 57.7398 33.1016L36.2475 54.5939C35.4123 55.4569 34.2708 55.9024 33.0737 55.9024L33.0459 55.9302ZM9.07579 31.8488L30.8465 53.6195C32.0158 54.7888 34.0481 54.7888 35.2174 53.6195L56.7098 32.1272C57.2944 31.5425 57.6006 30.763 57.6006 29.9557C57.6006 29.1483 57.2944 28.3409 56.7098 27.7841L34.9668 5.95772L9.07579 31.8488Z" fill="#070707" stroke={color2} strokeMiterlimit="10"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.9878 27.2552L30.3733 3.86971C31.8488 2.3942 34.6328 0.918692 35.4401 3.56348L36.2753 6.26394L9.38202 33.1572L6.68156 32.3499C3.73054 31.459 5.51229 28.7585 6.9878 27.283V27.2552Z" fill={color} stroke={color2} strokeMiterlimit="10"/>
            <path d="M9.5769 33.9367L6.45884 32.9902C5.06685 32.5726 4.59357 31.7652 4.42653 31.1806C4.00894 29.4545 5.70717 27.5057 6.45884 26.7541L29.8721 3.3686C30.8187 2.42204 32.3499 1.39197 33.714 1.39197C34.3822 1.39197 35.5793 1.64253 36.1083 3.3686L37.0548 6.48666L9.5769 33.9367ZM33.7419 2.8118C32.9902 2.8118 31.8209 3.45212 30.9022 4.37083L7.48892 27.7563C6.31964 28.9256 5.65149 30.1505 5.81853 30.8187C5.90205 31.1528 6.26396 31.4311 6.87644 31.626L9.18715 32.322L35.468 6.06906L34.772 3.75835C34.5214 2.92316 34.076 2.8118 33.7419 2.8118Z" fill="#070707" stroke={color2} strokeMiterlimit="10"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_1700_2299" x1="65.3372" y1="30.1596" x2="65.3372" y2="105.472" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1700_2299" x1="65.3372" y1="29.6295" x2="65.3372" y2="106.089" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint2_linear_1700_2299" x1="65.3486" y1="63.9767" x2="65.3486" y2="69.1638" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint3_linear_1700_2299" x1="65.3372" y1="63.423" x2="65.3372" y2="69.7357" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint4_linear_1700_2299" x1="65.3372" y1="34.8596" x2="65.3372" y2="100.416" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint5_linear_1700_2299" x1="65.3372" y1="34.3287" x2="65.3372" y2="101.01" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint6_linear_1700_2299" x1="108.26" y1="7.70239" x2="108.26" y2="47.5381" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint7_linear_1700_2299" x1="108.371" y1="6.92893" x2="108.371" y2="48.1387" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint8_linear_1700_2299" x1="118.511" y1="4.6986" x2="118.511" y2="29.4243" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint9_linear_1700_2299" x1="118.48" y1="4.17438" x2="118.48" y2="30.0877" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint10_linear_1700_2299" x1="24.4873" y1="86.2722" x2="24.4873" y2="126.108" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint11_linear_1700_2299" x1="24.3671" y1="85.7493" x2="24.3671" y2="126.931" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint12_linear_1700_2299" x1="14.2363" y1="103.834" x2="14.2363" y2="128.537" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint13_linear_1700_2299" x1="14.2899" y1="103.237" x2="14.2899" y2="129.151" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint14_linear_1700_2299" x1="105.706" y1="88.8345" x2="105.706" y2="128.67" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint15_linear_1700_2299" x1="105.803" y1="88.3337" x2="105.803" y2="129.494" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint16_linear_1700_2299" x1="116.012" y1="106.415" x2="116.012" y2="131.014" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint17_linear_1700_2299" x1="115.959" y1="105.796" x2="115.959" y2="131.599" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint18_linear_1700_2299" x1="27.0525" y1="5.14082" x2="27.0525" y2="44.9986" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint19_linear_1700_2299" x1="26.9408" y1="4.36655" x2="26.9408" y2="45.5763" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint20_linear_1700_2299" x1="16.7583" y1="2.24128" x2="16.7583" y2="26.8619" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
            <linearGradient id="paint21_linear_1700_2299" x1="16.7796" y1="1.69947" x2="16.7796" y2="27.5025" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDA34F"/>
            <stop offset="1" stopColor="#A77C27"/>
            </linearGradient>
        </defs>
    </svg>
    <span className='table-number' style={{color : `${textColor}`}}>T{tabelNumber}</span>
    
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <g className={newOrder ? "" : "d-none-not"} clipPath="url(#clip0_780_17614)">
            <path d="M7.50003 14.2833C7.7625 14.2773 8.01438 14.1786 8.21111 14.0047C8.40784 13.8309 8.53675 13.5931 8.57503 13.3333H6.38336C6.42273 13.6001 6.55766 13.8435 6.76307 14.0183C6.96848 14.193 7.23037 14.2872 7.50003 14.2833Z" fill="#F20000"/>
            <path d="M13.6875 11.7208L13.5458 11.5958C13.1439 11.2377 12.7921 10.8271 12.5 10.375C12.1809 9.75104 11.9897 9.06966 11.9375 8.37081V6.31248C11.9358 6.06246 11.9135 5.813 11.8708 5.56665C11.165 5.42158 10.5311 5.03707 10.0762 4.47822C9.62141 3.91937 9.37368 3.22052 9.37498 2.49998C9.37498 2.41248 9.37498 2.32498 9.37498 2.23748C8.93995 2.02339 8.47255 1.88261 7.99165 1.82081V1.29581C7.99165 1.14829 7.93304 1.0068 7.82872 0.902485C7.72441 0.798168 7.58292 0.739563 7.4354 0.739563C7.28787 0.739563 7.14639 0.798168 7.04207 0.902485C6.93775 1.0068 6.87915 1.14829 6.87915 1.29581V1.84165C5.80236 1.99354 4.81689 2.52987 4.10466 3.35162C3.39242 4.17336 3.00153 5.22504 3.00415 6.31248V8.37081C2.95196 9.06966 2.76072 9.75104 2.44165 10.375C2.15451 10.826 1.80839 11.2365 1.41248 11.5958L1.27081 11.7208V12.8958H13.6875V11.7208Z" fill="#F20000"/>
            <path d="M12.5 4.58335C13.6506 4.58335 14.5834 3.65061 14.5834 2.50002C14.5834 1.34943 13.6506 0.416687 12.5 0.416687C11.3494 0.416687 10.4167 1.34943 10.4167 2.50002C10.4167 3.65061 11.3494 4.58335 12.5 4.58335Z" fill="#F20000"/>
        </g>
        <defs>
            <clipPath id="clip0_780_17614">
            <rect width="15" height="15" fill="white"/>
            </clipPath>
        </defs>
    </svg>    
    </div>
    <div className={role_name === "Captain" || role_name === "SuperAdmin" ? "" : "d-none" } style={{position : "absolute" , left : "50%" , width : "100%" , transform : "translateX(-50%)" , bottom : "10px"}}>
    <svg onClick={() => {setResetPopUP(1);setDeletedTableNumber(tabelNumber) }} className={color  === "white" ? "d-none" : 'reset-btn-table'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <rect width="20" height="20" rx="10" fill="#CDA34F"/>
        <path d="M4.44617 10.9899C4.27475 10.8273 4.00485 10.8305 3.83752 10.9973C4.0089 11.1599 4.27886 11.1568 4.44617 10.9899ZM7.68833 12.1012C7.85306 12.2614 8.11541 12.2614 8.28014 12.1012L11.975 8.50719C12.1463 8.34056 12.1463 8.0654 11.975 7.89878C11.8103 7.73856 11.5479 7.73856 11.3832 7.89879L7.68834 11.4928C7.51705 11.6594 7.51704 11.9346 7.68833 12.1012ZM7.68834 7.89879C7.51705 8.06541 7.51705 8.34054 7.68834 8.50716L11.3832 12.1012C11.5479 12.2614 11.8103 12.2614 11.975 12.1012C12.1463 11.9346 12.1463 11.6594 11.975 11.4928L8.28011 7.89879C8.11539 7.73856 7.85306 7.73856 7.68834 7.89879ZM15.1393 10C15.1393 12.8513 12.763 15.1628 9.83167 15.1628V16C13.2383 16 16 13.3137 16 10H15.1393ZM4.52403 10C4.52403 7.14867 6.90034 4.83721 9.83167 4.83721V4C6.42499 4 3.66333 6.68629 3.66333 10H4.52403ZM9.83167 4.83721C12.763 4.83721 15.1393 7.14867 15.1393 10H16C16 6.68629 13.2383 4 9.83167 4V4.83721ZM4.35553 10.7304C4.47899 10.6806 4.55242 10.5539 4.54142 10.4212C4.52991 10.2823 4.52403 10.1419 4.52403 10H3.66333C3.66333 10.1493 3.77042 10.3398 3.86497 10.5214C3.95349 10.6914 4.17774 10.8021 4.35553 10.7304ZM9.83167 15.1628C8.15949 15.1628 6.66775 14.4109 5.69435 13.2343C5.54693 13.0561 5.28877 13.0057 5.09601 13.1335C4.89609 13.266 4.84365 13.5383 4.99518 13.7243C6.12448 15.11 7.87117 16 9.83167 16V15.1628ZM4.44617 10.9899L5.88591 9.55393C6.05516 9.38512 6.05171 9.10991 5.87828 8.9454C5.71151 8.7872 5.44911 8.79049 5.28636 8.95282L4.12909 10.1071C3.93787 10.2978 3.83193 10.5578 3.83536 10.8278L3.83752 10.9973L4.44617 10.9899ZM3.83752 10.9973C4.16678 10.6691 4.16024 10.1338 3.82307 9.81379L2.90039 8.93797C2.73379 8.77983 2.47158 8.78303 2.30888 8.9452C2.13969 9.11383 2.14305 9.38885 2.31631 9.55331L3.83752 10.9973Z" fill="white" fillOpacity="0.958352"/>
    </svg>
    <svg onClick={()=>{deleteFunction()}} className={ tabelNumber === parseInt(localStorage.getItem("TheLasttableNumber")) ? 'reset-btn-table' : "d-none" } xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <rect width="20" height="20" rx="10" fill="#FF1F00"/>
        <path d="M13 5.33333V6H11.8V5H8.2V6H7V5.33333C7 4.97971 7.12643 4.64057 7.35147 4.39052C7.57652 4.14048 7.88174 4 8.2 4H11.8C12.1183 4 12.4235 4.14048 12.6485 4.39052C12.8736 4.64057 13 4.97971 13 5.33333Z" fill="white"/>
        <path d="M14.4444 7H5.55556C5.40821 7 5.26691 7.05926 5.16272 7.16475C5.05853 7.27024 5 7.41332 5 7.5625C5 7.71168 5.05853 7.85476 5.16272 7.96025C5.26691 8.06574 5.40821 8.125 5.55556 8.125H6.11111V14.875C6.11111 15.1734 6.22817 15.4595 6.43655 15.6705C6.64492 15.8815 6.92754 16 7.22222 16H12.7778C13.0725 16 13.3551 15.8815 13.5635 15.6705C13.7718 15.4595 13.8889 15.1734 13.8889 14.875V8.125H14.4444C14.5918 8.125 14.7331 8.06574 14.8373 7.96025C14.9415 7.85476 15 7.71168 15 7.5625C15 7.41332 14.9415 7.27024 14.8373 7.16475C14.7331 7.05926 14.5918 7 14.4444 7ZM9.44444 13.1875C9.44444 13.3367 9.38591 13.4798 9.28173 13.5852C9.17754 13.6907 9.03623 13.75 8.88889 13.75C8.74155 13.75 8.60024 13.6907 8.49605 13.5852C8.39187 13.4798 8.33333 13.3367 8.33333 13.1875V9.8125C8.33333 9.66332 8.39187 9.52024 8.49605 9.41475C8.60024 9.30926 8.74155 9.25 8.88889 9.25C9.03623 9.25 9.17754 9.30926 9.28173 9.41475C9.38591 9.52024 9.44444 9.66332 9.44444 9.8125V13.1875ZM11.6667 13.1875C11.6667 13.3367 11.6081 13.4798 11.5039 13.5852C11.3998 13.6907 11.2585 13.75 11.1111 13.75C10.9638 13.75 10.8225 13.6907 10.7183 13.5852C10.6141 13.4798 10.5556 13.3367 10.5556 13.1875V9.8125C10.5556 9.66332 10.6141 9.52024 10.7183 9.41475C10.8225 9.30926 10.9638 9.25 11.1111 9.25C11.2585 9.25 11.3998 9.30926 11.5039 9.41475C11.6081 9.52024 11.6667 9.66332 11.6667 9.8125V13.1875Z" fill="white"/>
    </svg>
    </div>
        </div>
    )
}
export default TableSvg ;