import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, CircularProgress, AlertTitle } from '@mui/material';
// import Iconify from '../../components/iconify';
import Alert from '@mui/material/Alert';
// import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import { useState } from 'react';
import axios from 'src/api/axios';
import { PERIOD_LOGIN } from 'src/constants/url';

const ChangePeriodLogin = ({ reload, setReload, period ,setPeriod }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [periodTime, setPeriodTime] = useState(period);
  const [message, setMessage] = useState(false);
  const [error, setError] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const handleClickOpen = () => {
    setPeriodTime(period)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage(false);
  };
  const handleChangePeriod = async () => {
    setLoading(true);
    const data = {
      JWT_TTL: periodTime,
    };
    await axios
      .post(PERIOD_LOGIN, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setPeriod(periodTime)
          setPeriodTime(period)
          setMessage(true);
          setOpen(false);
        }
      })
      .catch((error) => {
        let errArr = []
        Object.values(error.response.data.errors).forEach(e => {
        errArr.push(e[0])
        })
        setError(true);
        showErrorAlert(errArr)
      });

    setLoading(false);
  };
  return (
    <>
      <Button variant="contained" onClick={handleClickOpen} className="btn-add">
        Change Period
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change Period</DialogTitle>
        {error ? (
              <Alert severity="error" className="m-2">
                <AlertTitle>Error</AlertTitle>
                {erroralert.map((e,i)=>{
                  if(e.indexOf("j w t") !== -1){
                    e = "The period field is required."
                  }
                  return(
                        <p style={{marginBottom :"5px"}} key={i}>{e}{e.indexOf("j w t t t l") !== -1}</p>
                  )
                })}
              </Alert>
            ) : (
              ''
            )}
        <DialogContent>
          <TextField
            label="Login Access Period"
            variant="filled"
            fullWidth
            className="mb-2"
            value={periodTime}
            onChange={(e) => setPeriodTime(e.target.value)}
          />
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={handleChangePeriod}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={2000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' , overflowX : "hidden"  }}

        >
          success! Login Access Period update Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default ChangePeriodLogin;
