import './order-archived.css'
import image from '../../assets/img/imgitems.png'
import { BaseUrl } from 'src/constants/url';
function OrderArchived ({item}) {
    return (
        <div className="order-item">
            <img src={`${BaseUrl}/${item.image}`} alt="" />
            <div className="quantity">{item.name} * {item.quantity}</div>
        </div>
    )
}
export default OrderArchived ;