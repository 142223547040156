import { Helmet } from 'react-helmet-async';
import './archive.css'
import { Grid } from '@mui/material';
import axios from 'src/api/axios';
import { ArchiveCont } from 'src/constants/url';
import { useEffect } from 'react';
import OrderArchived from './OrderArchived';
import { useNavigate } from 'react-router-dom';
import FixedSizeGridNoti from './BasicExampleDataGridProNoti';
const VISIBLE_FIELDS = ['name', 'rating', 'country', 'dateCreated', 'isAdmin'];
function ArchiveNotificatios () {
    const navigate = useNavigate()
    return(
        <div className='archive-section' style={{overflow : "hidden"}}>
            <Helmet>
                <title>Archive | Fenice Palas</title>
            </Helmet>
            <div className="route-cont" style={{
                display : "flex",
            }}>
                <p className='p1' onClick={() => navigate("/dashboard/archive")}>Orders Archive</p>
                <p className='p3 active-p'>Notifications Archive</p>
                <p className='p2' onClick={() => navigate("/dashboard/archive/archive-waiters")}>Waiters Archive</p>
            </div>
            <h2>Archived Notifications</h2>
            <div className="order-archive-container">
            <Grid container item>
            <Grid item xs={12} lg={12} xl={12} md={12} sm={12} >
            <FixedSizeGridNoti/>
            </Grid>
            </Grid>
            </div>
        </div>
    )
}
export default ArchiveNotificatios;