import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { CheckBox } from '@mui/icons-material';
import './archive.css'
import { ArchiveContOrders, DestroyOrders } from 'src/constants/url';
import axios from 'src/api/axios';
import { Spinner } from 'react-bootstrap';

export default function FixedSizeGrid({clickedOrder , setClickedOrder , setLoadData}) {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [data , SetData] = React.useState([])
  const FetchArchive = async () => {
      await axios.get(ArchiveContOrders , {
          headers : {
              Accept: 'application/json',
          }
      }).then(res => {
            if(res.status === 200){
                let Data = [];
                res.data.map(element => {
                    let date = element.created_at ;
                    element.created_at = element.created_at.slice(0,10)
                    element.time = date.slice(11,19)
                    Data.push(element)
                  });
                  SetData(Data)
            }
      }).finally(()=> {
        
      })
  }
  const [deleteLoad , setDeleteLoad] = React.useState(false);
  const [showSure , setShowSure] = React.useState(false)
  React.useEffect(() => {
      FetchArchive()
  },[deleteLoad])
  const deleteArchive = async () => {
    setDeleteLoad(true)
    await axios.post(DestroyOrders , {
      billNum : selectedRows
    },{
      headers : {
        Accept: 'application/json',
      }
    }).then(res => {
      if(res.status === 200){
        setSelectedRows([])
        setClickedOrder(0)
        setDeleteLoad(false)
      }
    })
  }
  const myData = [
    { id: 1, order_No: 1, table_No: 'Item 1', waiter: 'Jasone', Time: '19:00 2/2/2023' },
    { id: 2, order_No: 2, table_No: 'Item 2', waiter: 'Jasone', Time: '19:00 2/2/2023' },
    { id: 3, order_No: 3, table_No: 'Item 3', waiter: 'Jasone', Time: '19:00 2/2/2023' },
    // Add more data as needed
  ];

  const columns = [
    // { field: 'id', headerName: 'ID', flex : 1 , minWidth : 50  },
    { field: 'orderNumber', headerName: 'Order No.', flex : 1 , minWidth : 50  },
    { field: 'tableNumber', headerName: 'Table No.', flex : 1 , minWidth : 100  },
    { field: 'waiterName', headerName: 'Waiter', flex : 1 , minWidth : 100  },
    { field: 'created_at', headerName: 'Date', flex : 2 ,minWidth : 120      },
    { field: 'time', headerName: 'time', flex : 2 ,minWidth : 100      },
    {
      field: 'check', // Field name for the checkbox column
      headerName: '', // Column header
     flex : 1 , 
     minWidth : 50 ,
      renderHeader : (params)=>(
        <svg style={{marginRight : "4px"}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <g clipPath="url(#clip0_1769_3122)">
            <path d="M4.125 2.24977V1.12402C4.125 1.02457 4.16451 0.929185 4.23484 0.858858C4.30516 0.788532 4.40054 0.749023 4.5 0.749023H7.5C7.59946 0.749023 7.69484 0.788532 7.76517 0.858858C7.83549 0.929185 7.875 1.02457 7.875 1.12402V2.24977H10.875C10.9745 2.24977 11.0698 2.28928 11.1402 2.35961C11.2105 2.42993 11.25 2.52532 11.25 2.62477C11.25 2.72423 11.2105 2.81961 11.1402 2.88994C11.0698 2.96026 10.9745 2.99977 10.875 2.99977H1.125C1.02554 2.99977 0.930161 2.96026 0.859835 2.88994C0.789509 2.81961 0.75 2.72423 0.75 2.62477C0.75 2.52532 0.789509 2.42993 0.859835 2.35961C0.930161 2.28928 1.02554 2.24977 1.125 2.24977H4.125ZM4.875 2.24977H7.125V1.49977H4.875V2.24977ZM2.25 11.2498C2.15054 11.2498 2.05516 11.2103 1.98484 11.1399C1.91451 11.0696 1.875 10.9742 1.875 10.8748V2.99977H10.125V10.8748C10.125 10.9742 10.0855 11.0696 10.0152 11.1399C9.94484 11.2103 9.84946 11.2498 9.75 11.2498H2.25ZM4.875 8.99977C4.97446 8.99977 5.06984 8.96026 5.14016 8.88994C5.21049 8.81961 5.25 8.72423 5.25 8.62477V4.87477C5.25 4.77532 5.21049 4.67993 5.14016 4.60961C5.06984 4.53928 4.97446 4.49977 4.875 4.49977C4.77554 4.49977 4.68016 4.53928 4.60984 4.60961C4.53951 4.67993 4.5 4.77532 4.5 4.87477V8.62477C4.5 8.72423 4.53951 8.81961 4.60984 8.88994C4.68016 8.96026 4.77554 8.99977 4.875 8.99977ZM7.125 8.99977C7.22446 8.99977 7.31984 8.96026 7.39017 8.88994C7.46049 8.81961 7.5 8.72423 7.5 8.62477V4.87477C7.5 4.77532 7.46049 4.67993 7.39017 4.60961C7.31984 4.53928 7.22446 4.49977 7.125 4.49977C7.02554 4.49977 6.93016 4.53928 6.85983 4.60961C6.78951 4.67993 6.75 4.77532 6.75 4.87477V8.62477C6.75 8.72423 6.78951 8.81961 6.85983 8.88994C6.93016 8.96026 7.02554 8.99977 7.125 8.99977Z" fill="#B5B7C0"/>
            </g>
            <defs>
            <clipPath id="clip0_1769_3122">
                <rect width="12" height="12" fill="white"/>
            </clipPath>
            </defs>
        </svg>
      ),
      headerAlign: 'center',
      renderCell: (params) => (
          // Render a clickable checkbox in the cell using the Checkbox component
        <input type='checkbox'
        style ={{
                width : "20px",
                height : "20px",
                textAlign : "center",
                width : "100%"
            }}
            color="primary"
            onChange={() => handleCheckboxClick(params.row)}
            checked={selectedRows.includes(params.row.orderNumber)}
            />
            ),
    },
    // Define more columns as needed
  ];

  const handleCheckboxClick = (row) => {
    // Toggle the selection status of the clicked row
    if (selectedRows.includes(row.orderNumber)) {
      setSelectedRows(selectedRows.filter((orderNumber) => orderNumber !== row.orderNumber));
    } else {
      setSelectedRows([...selectedRows, row.orderNumber]);
    }
  };

  return (
    <div className='table-container' style={{ width: '100%', background : "white" ,padding : "20px" }}>
        <p className="title-mui">All  Orders</p>
      <div style={{ height: 350, width: '100%' }}>
        <DataGrid style={{width : "100%"}} rows={data} columns={columns} onRowClick={(e) =>{setClickedOrder(e.row.orderNumber); setLoadData(true)}}/>
      </div>
      <div onClick={()=>setShowSure(true)} className={selectedRows.length === 0 ? "d-none" :"selected"}>{deleteLoad ? <Spinner animation="border" variant="light" style={{width : "30px" , height : "30px" }} /> :`Delete ${selectedRows.length > 1 ? selectedRows.length : ""} permanently`}</div>
      <div className={showSure ? "layer1-overlay-archive" : "d-none" }>
        <div className="are-u-sure-popup">
          <span onClick={()=>setShowSure(false)}>&#10005;</span>
          <p className='text-sure'>{`Are You Sure You Want To Delete ${selectedRows.length > 1 ? selectedRows.length : ""}  ${selectedRows.length > 1 ? "elements" : "one element"} Permanently`}</p>
          <div className="btns-Sure">
            <p className='not-sure' onClick={()=>setShowSure(false)}>Cancel</p>
            <p className='yes-sure' onClick={()=>{deleteArchive(); setShowSure(false)}}>Yes</p>
          </div>
        </div>
      </div>
    </div>
  );
}
