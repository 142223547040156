import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useEffect, useState } from 'react';
import axios from 'src/api/axios';
import {
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  //   DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
} from '@mui/material';
import ImageUploading from 'react-images-uploading';
import { ADD_LANG, ITEMS, STORAGE } from 'src/constants/url';
import CategorySection from '../CategorySection';
const UpdateItems = ({ reload, setReload, id, i }) => {
  const [all, setAll] = useState([]);
  const [loadingLang, setLoadingLang] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [price, setPrice] = useState(i.price);
  const [offer_price, setOfferPrice] = useState(i.offer_price != null ? i.offer_price : '');
  const [weight, setWeight] = useState(i.weight);
  const [trending, setTrending] = useState(i.is_trending);
  const [vegan, setVegan] = useState(i.vegan);
  const [vegetarian, setVegetarian] = useState(i.vegetarian);
  const [lactose, setLactose] = useState(i.lactose_free);
  const [gluten, setGluten] = useState(i.gluten_free);
  const [images, setImages] = useState([]);
  const [category_section, setcategory_section] = useState(i.category != null ? i.category.id : '');
  const [showImage, setshowImage] = useState(i.image);
  const [error, setError] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const [empty, setEmpty] = useState('');
  const [name , setName] = useState(i.name);
  const [desc , setDesc] = useState(i.description);
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const UpdateItemForm = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append(`name`, name);
    formData.append(`description`, desc);
    formData.append('price', price);
    formData.append('is_trending', trending);
    formData.append('vegan', vegan);
    formData.append('vegetarian' , vegetarian);
    formData.append('lactose_free', lactose);
    formData.append('gluten_free', gluten);
    if (offer_price != null) {
      formData.append('offer_price', offer_price);
    }

    formData.append('offer_price', offer_price);
    formData.append('weight', weight);
    {
      category_section != null ? formData.append('category_id', category_section) : '';
    }
    if (images.length != 0) {
      formData.append('image', images[0].file);
    }
    axios
      .post(`${ITEMS}/${id}?_method=PUT`, formData, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(() => {
        setMessage(true);
        setTimeout(() => {
          setReload(!reload);
        }, 1500);
        setOpen(false);
        setError(false);
      }) .catch(error => {
        // Handle errors
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          console.log('Response status:', error.response.status);
          console.log('Response data:', error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Request was made but no response received:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error setting up the request:', error.message);
        }
        console.log('Error config:', error.config);
      })
      .catch((error) => {
        setError(true);
        showErrorAlert(error.response.data.message);
        setEmpty('please add category then try to update item');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen} className="btn-bg border-0 text-white mt-2">
        <ModeEditOutlineOutlinedIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update Item</DialogTitle>
        <DialogContent>
          <>
            {error ? (
              <Alert severity="error" className="mb-4">
                <AlertTitle>Error</AlertTitle>
                <p>{erroralert}</p>
                {category_section != null ? '' : empty}
              </Alert>
            ) : (
              ''
            )}
            <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
              {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                <div className="upload__image-wrapper position-relative mb-3">
                  <button
                    className="btn-add w-100 d-block text-white border-0 py-2"
                    style={isDragging ? { color: 'red' } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                    type="button"
                  >
                    upload Image
                  </button>
                  {imageList.length > 0 ? (
                    imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image['data_url']} alt="" width="100" />
                        <div className="image-item__btn-wrapper">
                          <button
                            onClick={() => onImageRemove(index)}
                            className="bg-danger border-0 text-white position-absolute bottom-0 end-0"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <img src={`${STORAGE}/${showImage}`} width="100%" />
                  )}
                </div>
              )}
            </ImageUploading>
            {loadingLang ? <Skeleton animation="wave" width="100%" /> :
              <>
                {
                  <TextField
                    id="filled-basic"
                    label={`Item Name`}
                    value={name}
                    // key={item.code}
                    variant="filled"
                    fullWidth
                    onChange={(e) => setName(e.target.value)}
                    className="mb-2"
                  />
                }
              </>
            }
            {loadingLang ? <Skeleton animation="wave" width="100%" /> :
              <>
                {
                  <TextField
                    id="filled-multiline-static"
                    label={`Item Description `}
                    value={desc}
                    multiline
                    rows={4}
                    variant="filled"
                    fullWidth
                    onChange={(e) => setDesc(e.target.value)}
                    className="mb-2"
                  />
                }
              </>
            }

            <TextField
              id="filled-basic"
              label="Item Price"
              value={price}
              type="number"
              variant="filled"
              fullWidth
              onChange={(e) => setPrice(e.target.value)}
              className="mb-2"
            />

            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                checked={trending === 1 ? true : false}
                onChange={(e) => setTrending(e.target.checked ? 1 : 0)}
                label=" Trending"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={vegan === 1 ? true : false}
                onChange={(e) => setVegan(e.target.checked ? 1 : 0)}
                label="vegan"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={vegetarian === 1 ? true : false}
                onChange={(e) => setVegetarian(e.target.checked ? 1 : 0)}
                label="vegetarian"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={lactose === 1 ? true : false}
                onChange={(e) => setLactose(e.target.checked ? 1 : 0)}
                label="lactose free"
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={gluten === 1 ? true : false}
                onChange={(e) => setGluten(e.target.checked ? 1 : 0)}
                label="gluten free"
              />
            </FormGroup>
            <CategorySection category_section={category_section} setcategory_section={setcategory_section} />
          </>
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={UpdateItemForm}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' , overflowX : "hidden"  }}

        >
          success! Item Updated Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default UpdateItems;
