import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'; // Add React and useEffect
import * as echarts from 'echarts'; // Import ECharts
import menuSvg from '../assets/img/Analytics/menu.svg'
import revenueSvg from '../assets/img/Analytics/revenue.svg'
import ordersSvg from '../assets/img/Analytics/orders.svg'
import visitorsSvg from '../assets/img/Analytics/visitors.svg'
import axios from 'src/api/axios';
import { OrderSummary, SalesAnalytics, StatisticsAnalytics, TopFiveItems, WaiterArchive } from 'src/constants/url';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Progress, Space } from 'antd';
import first from './archive/first_1041006 1.svg'
import second from './archive/first_1041006 2.svg'
import third from './archive/first_1041006 3.svg'
import DatePicker from 'src/components/DatePicker';
import Iconify from 'src/components/iconify';
import MOnthPicker from 'src/components/MonthPicker';
import DatePicker2 from 'src/components/DatePicker2';

export default function DashboardAppPage() {
  const [topFiveData ,setTopFiveData] = useState([])
  const [statistics , setStatistics] = useState([])
  const FetchTopFive = async () => {
    await axios.get(TopFiveItems , {
      headers :{
        Accept: 'application/json',
      }
    }).then(res=>{
      let arr = [];
Object.values(res.data).forEach(e => {
  const { id, orders_count, ...rest } = e;
  arr.push({  value: orders_count ,...rest });
});
setTopFiveData(arr);
    }).catch(error => {
      // Handle errors
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log('Response status:', error.response.status);
        console.log('Response data:', error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.log('Request was made but no response received:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error setting up the request:', error.message);
      }
      console.log('Error config:', error.config);
    })
  }
  const FetchStatistics = async() =>{
    await axios.get(StatisticsAnalytics , {
      headers : {
        Accept: 'application/json',
      }
    }).then(res => {
      if(res.status === 200){
        setStatistics(res.data.data)
      }
    }).catch(error => {
      // Handle errors
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log('Response status:', error.response.status);
        console.log('Response data:', error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.log('Request was made but no response received:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error setting up the request:', error.message);
      }
      console.log('Error config:', error.config);
    })
  }
  useEffect(()=>{
    FetchTopFive()
    FetchStatistics()
  },[])
  useEffect(() => {
    // Initialize the chart when the component mounts
    const chartDom = document.getElementById('main');
    const myChart = echarts.init(chartDom);
    
    const option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        icon :"circle",
        orient: 'vertical',
        right : '10%',
        top : "center",
        width :"auto",
        textStyle: {
          fontSize : "12px",
          fontWeight: 'bold',
          fontFamily : "Poppins",
          color : ['#B8B8B8', '#000', '#CDA34F', '#A77C27', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
        },
      },
      width :"auto",
      series: [
        {
          name: 'Fenice Palas Analytics',
          type: 'pie',
          radius: ['30%', '70%'],
          left: 0,
          right : 200,
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 12,
              fontWeight: 'bold',
              fontFamily : "Poppins",
            }
          },
          labelLine: {
            show: false
          },
          itemStyle: {
            // borderRadius: 10,
            left : "left",
            borderColor: '#FFF',
            borderWidth: 6,
          },       
            data: topFiveData,
          color : ['#B8B8B8', '#000', '#CDA34F', '#A77C27', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        }
      ]
    };

    myChart.setOption(option);

    // Clean up the chart when the component unmounts
    return () => {
      myChart.dispose();
    };
  }, [topFiveData]); // Empty dependency array to ensure this effect runs only once

  const percentage = 95;
  const [show3 , setShow3] = useState(0)
  const [value , setValue] = useState("Weekly")
  const [activeFilter , setActiveFilter] = useState("All")
  const [activeFilter2 , setActiveFilter2] = useState("All")
  const [WaitersData , setWaitersData] = useState([])
  const [showDatePicker , setShowDatePicker] = useState(0)
  const [showDatePicker2 , setShowDatePicker2] = useState(0)
   // Function to get a date one month ago
   const getOneMonthAgo = () => {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    return today;
  }
  
  // Function to format the date as yyyy-mm-dd
  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const [customDateWaiters, setCustomDateWaiters] = useState({
    startDate: getFormattedDate(getOneMonthAgo()),
    endDate: getFormattedDate(new Date())
  });
  
  const[waitersDataWithRequest , setWaitersDataWithRequest] = useState({})
  const[waitersDataWithRequest2 , setWaitersDataWithRequest2] = useState({})
  const[totalWaiters , setTotalWaiters] = useState(0)
  const[totalThreeWaiters , setTotalThreeWaiters] = useState(0)
  const FetchWaitersInfos = async () => {
    await axios.post(WaiterArchive , waitersDataWithRequest , {
      headers :{
        Accept: 'application/json',
      }
    }).then(res => {
      if(res.status === 200){
        let responseVALUES = Object.values(res.data.data.summary)
        let responseNAMES = Object.keys(res.data.data.summary)
        for(let i = 0 ; i < responseVALUES.length ; i++) {
          responseVALUES[i].name = responseNAMES[i]
          responseVALUES[i].id = i + 1
        }
        setWaitersData(responseVALUES)
        setTotalThreeWaiters(res.data.data.topThreeTotal)
        setTotalWaiters(res.data.data.allTotal)
    }
    }).catch(error => {
      // Handle errors
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log('Response status:', error.response.status);
        console.log('Response data:', error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.log('Request was made but no response received:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error setting up the request:', error.message);
      }
      console.log('Error config:', error.config);
    })
  }
  useEffect(() =>{
    FetchWaitersInfos()
  },[waitersDataWithRequest])

  const[salesSummary , setSalesSummary] = useState([])
  const [selectedMonthYear , setSelectedMonthYear] = useState({})
  const [percentageSales , setPercentageSales] = useState(0)
  const FetchSalesSummary = async () => {
    await axios.post(SalesAnalytics , selectedMonthYear , {
      headers :{
        Accept: 'application/json',
      }
    }).then(res => {
      if(res.status === 200) {
        setSalesSummary(res.data.data)
        setPercentageSales((parseInt(res.data.data.selectedMontIncome) / parseInt(res.data.data.averageMonthlyIncome)) * 100)
      }
    }).catch(error => {
      // Handle errors
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log('Response status:', error.response.status);
        console.log('Response data:', error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.log('Request was made but no response received:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error setting up the request:', error.message);
      }
      console.log('Error config:', error.config);
    })
  }
  useEffect(()=>{
    FetchSalesSummary()
  },[selectedMonthYear])

  const[ordersSummaryData , setOrdersSummaryData] = useState({})
  const FetchOrdersSummary = async () => {
    await axios.post(OrderSummary , waitersDataWithRequest2 , {
      headers :{
        Accept: 'application/json',
      }
    }).then(res => {
      if(res.status === 200) {
        setOrdersSummaryData(res.data.data)
      }
    }).catch(error => {
      // Handle errors
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log('Response status:', error.response.status);
        console.log('Response data:', error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.log('Request was made but no response received:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error setting up the request:', error.message);
      }
      console.log('Error config:', error.config);
    })
  }
  useEffect(()=>{
    FetchOrdersSummary()
  },[waitersDataWithRequest2])
  return (
    <>
      <Helmet>
        <title> Dashboard | Fenice Palas </title>
      </Helmet>

      <Container maxWidth="xl" style={{background: "#FAEFDA" , padding : "30px 10px", userSelect : "none"}}>
        <Grid container style={{gap : "20px"}}>
        <Grid item style={{ margin : "0"}} lg={12} md={12} sm={12} xs={12}>
          <div className="total-analytics">
            <div className="menu-total item-total">
              <div className="text-total">
              <div className="count-total">{statistics.tatalMenu}</div>
              <p>Total Menu</p>
              </div>
              <img src={menuSvg} alt="" />
            </div>
            <div className="orders-total item-total">
            <div className="text-total">
              <div className="count-total">{statistics.totalRevenue}</div>
              <p>Total Revenue</p>
              </div>
              <img src={revenueSvg} alt="" />
            </div>
            <div className="revenue-total item-total">
            <div className="text-total">
              <div className="count-total">{statistics.totalOrders}</div>
              <p>Total Orders</p>
              </div>
              <img src={ordersSvg} alt="" />
            </div>
            <div className="visitors-total item-total">
            <div className="text-total">
              <div className="count-total">{statistics.totalVisitors}</div>
              <p>Total Visitor</p>
              </div>
              <img src={visitorsSvg} alt="" />
            </div>
          </div>
        </Grid>
        </Grid>
        <Grid container style={{gap : "20px" , justifyContent : "center"}}>  
        <Grid item style={{textAlign : "start" ,background : "white", borderRadius : "20px" , position :"relative", display : "flex" , alignItems : "center" }} lg={6} md={6} sm={12} xs={12}>
          <div className="text-analytics-1">
            <h3>Most Selling Items</h3>
            <p>Our top-selling products .</p>
          </div>
        <div id="main" style={{ width: '100%', height: '400px'}}></div>
        </Grid>
        <Grid className='grid1' item style={{background : "white", borderRadius : "20px" }} lg={6} md={6} sm={12} xs={12}>
          <div className="card-body-analytics">
            <div className="title-analytics">
              <div className="text-title">
                <h4>Orders Summary</h4>
                <p>Lorem ipsum dolor sit amet,consecteture</p>
              </div>
              <div className="filters-period" style={{display : "flex" , justifyContent : 'center'}}>
                <ul className='filters-analytics' style={{width : "126px"}}>
                  <li className={activeFilter === "All" ? "active-li" : ""} onClick={() => {setActiveFilter("All"); setWaitersDataWithRequest2({})}}>All</li>
                  <li className={activeFilter === "Custom" ? "active-li" : ""} onClick={() => {setActiveFilter("Custom");setShowDatePicker2(1);setWaitersDataWithRequest2(customDateWaiters)}}>Custom</li>
                </ul>
              </div>
            </div>
            <div className="analytics">
              <div className="left-side-analytics">
                <div className="container-circuler" style={{width : "175px" , height  :"175px"}}>
              <CircularProgressbar value={ordersSummaryData.percentage === undefined ? 0 : parseInt(ordersSummaryData.percentage)} text={`${ordersSummaryData.percentage === undefined ? "0" : ordersSummaryData.percentage }%`}
            circleRatio ={0.7}
            counterClockwise	= {false}
            strokeWidth = {11}
            styles={buildStyles({
              // Rotation of path and trail, in number of turns (0-1)
              rotation: 0.65,
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'butt',
              
              // Text size
              textSize: '16px',
              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 0.5,
              
              // Can specify path transition in more detail, or remove it entirely
              // pathTransition: 'none',
              
              // Colors
              textColor : "#3A3A3D",
              pathColor: `#CDA34F`,
              trailColor: '#d6d6d6',
              backgroundColor: '#3e98c7',
            })}
            />
            </div>
              </div>
              <div className="right-side-bottom-summary" style={{width : "100%" , display : "flex" , flexDirection : "column" , justifyContent : "center" , alignItems : "center"}}>
                <div className="income1">
                  <div className="rectangle" style={{background: "rgba(205, 163, 79, 0.96)"}}></div>
                  <div className="text-income">
                  <div className="num2">{ordersSummaryData.selectedPeriod}</div>
                  <span className='income-span'>Selected Period</span>
                  </div>
                </div>
                {/* <div className="income1">
                  <div className="rectangle" style={{background: "#C49062"}}></div>
                  <div className="text-income">
                  <div className="num2">{ordersSummaryData.selectedMonth}</div>
                  <span className='income-span'>Selected Month</span>
                  </div>
                </div>
                <div className="income1">
                  <div className="rectangle" style={{background: "rgb(137 95 57)"}}></div>
                  <div className="text-income w-100">
                  <div className="num2">{ordersSummaryData.selectedYear}</div>
                  <span className='income-span'>Slected <br /> Year</span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="bottom-side-analytics">
              <div className="quan">
                <div className="num">{ordersSummaryData.onDelivery}</div>
                <div className="stat">On Delivery</div>
              </div>
              <div className="quan">
                <div className="num">{ordersSummaryData.deliverd}</div>
                <div className="stat">Delivered</div>
              </div>
              <div className="quan">
                <div className="num">{ordersSummaryData.total}</div>
                <div className="stat">total</div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className='grid1' item style={{background : "white", borderRadius : "20px" }} lg={6} md={6} sm={12} xs={12} >
            <div className="summary-container" >
              <div className="top-side-summary">
              <div className="text-summary">
                <h5>Sales Summary</h5>
                <p>Lorem ipsum dolor</p>
              </div>
              {/* <div className="selection">
                <div className="selected-vlaue" onClick={()=>{setShow3(!show3)}}>{value}<div className={show3 ? "icon animate-icon" : "icon"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                  <path d="M5.25001 5.15002L9.79665 0.425015H0.703368L5.25001 5.15002Z" fill="#A77C27"/>
                </svg>
                  </div></div>
                <ul className={show3 ?  "selection-list" : "selection-list d-none"}>
                  <li onClick={()=>{setValue("Yearly");setShow3(!show3)}}>Yearly</li>
                  <li onClick={()=>{setValue("Monthly");setShow3(!show3)}}>Monthly</li>
                  <li onClick={()=>{setValue("Weekly");setShow3(!show3)}}>Weekly</li>
                  <li onClick={()=>{setValue("Daily");setShow3(!show3)}}>Daily</li>
                </ul>
              </div> */}
              <MOnthPicker setSelectedMonthYear={setSelectedMonthYear}/>
              </div>
              <div className="bottom-side-summary">
              <Space wrap>
                <span className='income'>Income</span>
                <Progress className='roun1' type="dashboard" percent={percentageSales.toFixed(0)} strokeColor={"#CDA34F"} strokeWidth={7} trailColor = {"#F4F4F4"} />
                <Progress className='roun2' type="dashboard" percent={percentageSales.toFixed(0)} strokeColor={"#C49062"} strokeWidth={7} trailColor = {"#F4F4F4"}/>
                <Progress className='roun3' type="dashboard" percent={percentageSales.toFixed(0)} strokeColor={"#FDE7AD"} trailColor = {"#F4F4F4"}/>
              </Space>
              <div className="right-side-bottom-summary">
                <div className="income1">
                  <div className="rectangle" style={{background: "rgba(205, 163, 79, 0.96)"}}></div>
                  <div className="text-income">
                  <div className="num2">{salesSummary.averageMonthlyIncome}</div>
                  <span className='income-span'>Average Income</span>
                  </div>
                </div>
                <div className="income1">
                  <div className="rectangle" style={{background: "#C49062"}}></div>
                  <div className="text-income">
                  <div className="num2">{salesSummary.currentMonthIncome}</div>
                  <span className='income-span'>Current Income</span>
                  </div>
                </div>
                <div className="income1">
                  <div className="rectangle" style={{background: "rgb(137 95 57)"}}></div>
                  <div className="text-income">
                  <div className="num2">{salesSummary.selectedMontIncome}</div>
                  <span className='income-span'>Slected Income</span>
                  </div>
                </div>
              </div>
              </div>
            </div>
        </Grid>
        <Grid className='grid1' item style={{background : "white", borderRadius : "20px" }} lg={6} md={6} sm={12} xs={12}>
          <div className="card-body-analytics">
            <div className="title-analytics">
              <div className="text-title">
                <h4>Waiter Achievements</h4>
                <p>Lorem ipsum dolor sit amet,consecteture</p>
              </div>
              <div className="filters-period" style={{display : "flex" , justifyContent : 'center'}}>
                <ul className='filters-analytics' style={{width : "126px"}}>
                  <li className={activeFilter2 === "All" ? "active-li" : ""} onClick={() => {setActiveFilter2("All"); setWaitersDataWithRequest({})}}>All</li>
                  <li className={activeFilter2 === "Custom" ? "active-li" : ""} onClick={() => {setActiveFilter2("Custom");setShowDatePicker(1);setWaitersDataWithRequest(customDateWaiters)}}>Custom</li>
                </ul>
              </div>
            </div>
            <div className="waiters-analytics">
              <div className="left-side-waiters">
                <div className="waiters-container">
                  <ul className='waiter-list'>
                    { WaitersData.length === 0 ? <p style={{fontFamily : "Poppins"}}>There's No Data <br/> During This Date</p> :
                      WaitersData.map((e,i) => {
                        return(
                          <li key={i}><span className="rank">{e.rank === 1 ? <img src={first} alt='img1'/> : e.rank === 2 ? <img src={second} alt='img2'/> : e.rank === 3 ? <img src={third} alt='img3'/> : e.rank}</span><span className='waiter-name'>{e.name}</span><span className="total-achiev">{e.total}</span></li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
              <div className="right-side-waiters">
                <div className="container-circuler" style={{width : "175px" , height  :"175px" , position : "relative"}}>
              <CircularProgressbar value={totalWaiters !== 0 ? (totalThreeWaiters / totalWaiters) * 100 : 0}
                circleRatio ={0.7}
                counterClockwise	= {false}
                strokeWidth = {11}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.65,
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'butt',
                  
                  // Text size
                  textSize: '16px',
                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,
                  
                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',
                  
                  // Colors
                  textColor : "#3A3A3D",
                  pathColor: `#CDA34F`,
                  trailColor: '#d6d6d6',
                  backgroundColor: '#3e98c7',
              })}
              />
            <span className='right-span-flow2'>{totalThreeWaiters}</span>
            <span className='right-span-flow'>Orders</span>
                </div>
                <p className='right-total'>Top Tier  <br /> Trio</p>
              </div>
            </div>
          </div>
        </Grid>
        </Grid>
      </Container>
      <div className={!showDatePicker ? "d-none" : "date-picker-overlay"}>
        <div className="content-date-picker">
          <div className="close-date-picker" onClick={()=>{setShowDatePicker(0)}}>
            <Iconify icon={"iconamoon:close-bold"}/>
          </div>
          <DatePicker setWaitersDataWithRequest={setWaitersDataWithRequest} />
        </div>
      </div>
      <div className={!showDatePicker2 ? "d-none" : "date-picker-overlay"}>
        <div className="content-date-picker">
          <div className="close-date-picker" onClick={()=>{setShowDatePicker2(0)}}>
            <Iconify icon={"iconamoon:close-bold"}/>
          </div>
          <DatePicker2 setWaitersDataWithRequest2={setWaitersDataWithRequest2} />
        </div>
      </div>
    </>
  );
}
