import Skeleton from '@mui/material/Skeleton';
import {
  Typography,
  Container,
  Grid,
  Stack,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { EndSessions, PERIOD_LOGIN, USER } from 'src/constants/url';
import axios from 'src/api/axios';
import { Helmet } from 'react-helmet-async';
import DeleteUser from './DeleteUser';
import AddUser from './AddUser';
import { useNavigate } from 'react-router-dom';
import UpdateUser from './UpdateUser';
import ChangePeriodLogin from './ChangePeriodLogin';
import { onMessageListener } from 'src/firebase';
import Iconify from 'src/components/iconify';
import { logOut } from 'src/layouts/dashboard/header/AccountPopover';
import { Spinner } from 'react-bootstrap';

const UserPage = () => {
  const navigate = useNavigate();
  const roles = localStorage.getItem('permissions');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [period, setPeriod] = useState('');
  const [reload, setReload] = useState(false);

  const FetchUsers = async () => {
    // setLoading(true);
    await axios
      .get(USER, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false)
          setUsers(response.data.data);
        } else if (response.status === 401) {
          navigate('/', { replace: true });
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const FetchUserLoginPeriod = async () => {
    await axios
      .get(PERIOD_LOGIN, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setPeriod(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  useEffect(() => {
    FetchUsers();
  }, []);
  useEffect(()=>{
    FetchUserLoginPeriod();
  },[period])
  onMessageListener().then(
    payload => {
      if(payload.notification.title === "Update" || payload.notification.title === "EndSession") {
        return payload
      } else {
        return null
      }
    }
  ).then(payload => {
    if(payload.notification.title === "Update") {
      FetchUsers()
    }
  }
  )
  const [showSure , setShowSure] = useState(false)
  const [spin_yes , setSpin_yes] = useState(true)
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState(null);

  const endSession = async (e) => {
    await axios.post(`${EndSessions}/${e}` , {} , {
      headers : {
        Accept: 'application/json',
      }
    } ).then((res)=>{
      if(res.status === 200) {
        FetchUsers()
        setShowSure(false)
        setSpin_yes(true)
      }
    }).catch(error=>{
      setSpin_yes(true)
    }) 
  }  
  return (
    <>
      <Helmet>
        <title> Users | Fenice Palas </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          {roles.includes('create-User') && <AddUser setLoading1={setLoading} FetchUsers={FetchUsers} setReload={setReload} reload={reload} />}
        </Stack>
        {loading ? (
          <></>
        ) : (
          <>
            {users.length > 0 ? (
              <>
                {period !== null ? (
                  <>
                    {roles.includes('change-JWT-TTL') && (
                      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h5">
                          Login Access Period: <span>{period.JWT_TTL} minutes</span>
                        </Typography>
                        <ChangePeriodLogin FetchUserLoginPeriod={FetchUserLoginPeriod} setReload={setReload} reload={reload} period={period.JWT_TTL} setPeriod={setPeriod}/>
                      </Stack>
                    )}
                  </>
                ) : (
                  ''
                )}
                <TableContainer component={Paper} className="mt-5">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">User-id</TableCell>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Email</TableCell>
                        <TableCell align="center">Role</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">End Session</TableCell>
                        <TableCell align="center">Edit & Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((item) => (
                        <TableRow key={item.id} id={item.id}>
                          <TableCell align="center">{item.id}</TableCell>
                          <TableCell align="center">{item.name}</TableCell>
                          <TableCell align="center">{item.email}</TableCell>
                          <TableCell align="center">{item.role_name}</TableCell>
                          <TableCell align='center'><span style={{width : "250px" , display : "block" , margin : "0 auto"}} className={item.status === "Active Now" ? "active_status" : "last_seen"}>{item.status === "Active Now" ? "Online" : "Last seen at : "+item.status.slice(0,10)+"   "+item.status.slice(11,20)}</span></TableCell>
                          <TableCell align='center'><span onClick={() => {setShowSure(true); setSelectedUserId(item.id);setSelectedUserName(item.name)}} className={item.status === "Active Now" && item.role_name !== "SuperAdmin" ? 'user-end' : "d-none"}><Iconify icon={'solar:user-block-bold'} color="#006097" width={30} /></span>
                          <div className={showSure ? "layer1-overlay-archive" : "d-none" }>
                          <div className="are-u-sure-popup">
                            <span onClick={()=>setShowSure(false)}>&#10005;</span>
                            <p className='text-sure'>{`Are You Sure You Want To End ${selectedUserName}'s Session ?`}</p>
                            <div className="btns-Sure">
                              <p className='not-sure' onClick={()=>setShowSure(false)}>Cancel</p>
                              <div className='yes-sure' style={{height : "35px"}} onClick={()=>{endSession(selectedUserId);setSpin_yes(false)}}>{spin_yes ? "Yes"  : <Spinner animation="border" variant="light" style={{width :"15px" , height :"15px"}} />} </div>
                            </div>
                          </div>
                        </div>
                          </TableCell>
                          <TableCell align="center" style={{display : "flex" , justifyContent : "center" , gap : "10px"}}>
                            {roles.includes('update-User') && (
                              <UpdateUser FetchUsers={FetchUsers} id={item.id} i={item} setReload={setReload} reload={reload} />
                            )}
                            {roles.includes('delete-User') && (
                              <DeleteUser setLoading1={setLoading} FetchUsers={FetchUsers} id={item.id} setReload={setReload} reload={reload} />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
                {' '}
                No Users List{' '}
              </Typography>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default UserPage;
