import {
  TextField,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  //   DialogContentText,
  DialogTitle,
  Alert,
  AlertTitle,
  Snackbar,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import axios from 'src/api/axios';
import { TABLE } from 'src/constants/url';

const CreateTable = ({ reload, setReload , setAddNewPopUP}) => {
  let roles = localStorage.getItem("role_name");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tablenum, setTableNum] = useState('');
  const [error, setError] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };
  const [item, setItem] = useState([]);

  const addNewItem = () => {
    const copyCart = [...item];
    copyCart.push(tablenum);
    setItem(copyCart);
    setTableNum("");
  };



  const CreateTableForm = () => {
    setLoading(true);
    setAddNewPopUP(1)
  };


  const deleteByIndex = index => {
    setItem(oldValues => {
      return oldValues.filter((_, i) => i !== index)
    })
  }

  return (
    <>
      <Button variant="contained" onClick={CreateTableForm} className={roles === "Waiter" ? "btn-add d-none" : "btn-add"} style={{  height:"48px"}}> 
        <AddIcon /> New Table
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create Table</DialogTitle>
        <DialogContent>
          <>
            {error ? (
              <Alert severity="error" className="mb-4">
                <AlertTitle>Error</AlertTitle>
                <p>{erroralert}</p>
              </Alert>
            ) : (
              ''
            )}
            <TextField
              id="filled-basic"
              label="Table Number"
              value={tablenum}
              type="number"
              variant="filled"
              fullWidth
              onChange={(e) => setTableNum(e.target.value)}
              className="mb-2"
            />
            {tablenum === '' ? <Button disabled className="btn-bg bg-secondary text-white fs-6 mb-2" style={{ opacity: '.6' }}>
              Add Table
            </Button>
              : <Button onClick={addNewItem} className="btn-bg bg-secondary text-white fs-6 mb-2">
                Add Table
              </Button>
            }
            <ul className='list-unstyled m-0 '>
              {item.map((subItems, sIndex) => {
                return <li key={sIndex} id={sIndex} className='list-table'> {subItems}
                  <button onClick={() => deleteByIndex(sIndex)} className="bg-transparent border-0 text-white ">
                   Delete</button>
                

                </li>
              })}
            </ul>

          </>
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={CreateTableForm}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' , overflowX : "hidden"  }}

        >
          success! Table Added Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateTable;
























// import { Helmet } from 'react-helmet-async';
// import React, { useState } from 'react';

// import {
//   Typography,
//   Container,
//   Grid,
//   Card,
//   CardMedia,
//   Stack,
//   Link,
//   TableContainer,
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
//   Checkbox,
//   Button,
//   Pagination,
// } from '@mui/material';
// import { TabList } from '@mui/lab';
// import { Tabs, Tab } from '@mui/material';
// import CreateTable from './CreateTable';
// import imageCard from '../../../src/assets/img/img.png';
// import noteCard from '../../assets/img/Group 1000001335.png';
// import note from '../../assets/img/Group (1).png';
// import imgeTable from '../../assets/img/table.svg';
// import imgeicone from "../../assets/img/Vector.png"
// import vectort from '../../assets/img/Vector (23).png';
// // import './TablesPage.css'; // Import your custom CSS file

// const PageTable = () => {
//   const [selectedTab, setSelectedTab] = useState(0);
//   const [selectedTab1, setSelectedTab1] = useState(0);
//   const [showTable, setShowTable] = useState(false); // State to control table visibility

//   const handleChange = (event, newValue) => {
//     setSelectedTab(newValue);
//   };
//   const handleChange1 = (event, newValue) => {
//     setSelectedTab1(newValue);
//   };
//   const [index, setIndex] = useState(0);
//   const total = 8; // Replace 8 with the actual total number of items

//   const slide = (offset) => {
//     const newIndex = Math.min(Math.max(index + offset, 0), total - 1);
//     setIndex(newIndex); 
//   }

//   const [page, setPage] = useState(1);
//   const handleChangePage = (event, value) => {
//     setPage(value);
//   };

//   return (
//     <>
//       <Helmet>
//         <title>Tables Page</title>
//       </Helmet>

//       <Container>
//         <Grid container spacing={1}>
//           <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
//             <Typography variant="h4" sx={{ mb: 2 }} className="nameTable">
//               Tables
//             </Typography>
//             {/* <div className="Alert">
//               <img src={vectort} />
//               <p className="Alert1">New Order 0102</p>
//               <p className="Alert2">Merge to Table’s order </p>
//             </div> */}
//             <CreateTable  />
//           </Grid>
//           <Grid item xs={12} sm={8} style={{}}>
//             <Grid container className="tablecard" spacing={1}>
//               <div className="TableContent">
//                 <img className="imgTable" src={imgeTable} alt="Card Image" />
//                 <div className="iconname">
//                   <img className="iconTable" src={imgeicone} alt="icon" />
//                   <p className="nametable">T1</p>
//                 </div>

//               </div>
//               <div className="TableContent">
//                 <img className="imgTable" src={imgeTable} alt="Card Image" />
//                 <div className="iconname">
//                   <img className="iconTable" src={imgeicone} alt="icon" />
//                   <p className="nametable">T1</p>
//                 </div>

//               </div>
//               <div className="TableContent">
//                 <img className="imgTable" src={imgeTable} alt="Card Image" />
//                 <div className="iconname">
//                   <img className="iconTable" src={imgeicone} alt="icon" />
//                   <p className="nametable">T1</p>
//                 </div>

//               </div>
//               <div className="TableContent">
//                 <img className="imgTable" src={imgeTable} alt="Card Image" />
//                 <div className="iconname">
//                   <img className="iconTable" src={imgeicone} alt="icon" />
//                   <p className="nametable">T1</p>
//                 </div>

//               </div>
//               <div className="TableContent">
//                 <img className="imgTable" src={imgeTable} alt="Card Image" />
//                 <div className="iconname">
//                   <img className="iconTable" src={imgeicone} alt="icon" />
//                   <p className="nametable">T1</p>
//                 </div>

//               </div>
//               <div className="TableContent">
//                 <img className="imgTable" src={imgeTable} alt="Card Image" />
//                 <div className="iconname">
//                   <img className="iconTable" src={imgeicone} alt="icon" />
//                   <p className="nametable">T1</p>
//                 </div>

//               </div>
//               <div className="TableContent">
//                 <img className="imgTable" src={imgeTable} alt="Card Image" />
//                 <div className="iconname">
//                   <img className="iconTable" src={imgeicone} alt="icon" />
//                   <p className="nametable">T1</p>
//                 </div>

//               </div>
//               <div className="TableContent">
//                 <img className="imgTable" src={imgeTable} alt="Card Image" />
//                 <div className="iconname">
//                   <img className="iconTable" src={imgeicone} alt="icon" />
//                   <p className="nametable">T1</p>
//                 </div>

//               </div>




//             </Grid>
//           </Grid>


//           <Grid item xs={12} sm={4} style={{}}>
//             <Grid container className="tablecard1" spacing={1} style={{ marginTop: '20px' }}>
//               <div className="notes" style={{ display: 'flex' }}>
//                 <button  className="btnNote" variant="contained"  style={{ marginRight: '10px' }}>
//                   All
//                 </button >

//                 <button  className="btnNote"  variant="contained" style={{ marginRight: '10px' ,width:"123px"}}>
                  
//                   In Progress
//                 </button >
//                 <button   className="btnNote" variant="contained"  style={{ marginRight: '10px' }}>
//                   Pending
//                 </button >

//                 {/* Render pagination buttons */}
//                 {/* <Pagination
//                   count={Math.ceil(20 / 10)} // Total number of pages
//                   page={page} // Current page
//                   onChange={handleChangePage} // Function to handle page change
//                   style={{ marginTop: '20px' }}
//                 /> */}
// <div>
//   <div className="counter">{index + 1} - {total}</div>
//   <button
//     className="paginate left"
//     onClick={() => slide(-1)}
//     disabled={index === 0}
//   >
//     <i class="fa-solid fa-chevron-left"></i>
//   </button>
//   <button
//     className="paginate right"
//     onClick={() => slide(1)}
//     disabled={index === total - 1}
//   >
//     <i class="fa-solid fa-chevron-right"></i>
//   </button>
// </div>

//                 <hr />
//               </div>

//               <TableContainer>
//                 <Table className="AllTable">
//                   <TableBody>
//                     <TableRow>
//                       <TableCell>
//                         <Typography>Table No.</Typography>
//                         <p className="dataTable">1</p>
//                       </TableCell>
//                       <TableCell>
//                         <Typography>Time</Typography>
//                         <p className="dataTable">102:22 pm</p>
//                       </TableCell>
//                       <TableCell>
//                         <Typography>Status</Typography>
//                         <p className=" dataTable1">In progress</p>
//                       </TableCell>
//                     </TableRow>
//                     {/* Add more rows here */}
//                   </TableBody>
//                 </Table>
//                 <Table className="AllTable">
//                   <TableBody>
//                     <TableRow>
//                       <TableCell>
//                         <Typography>Table No.</Typography>
//                         <p className="dataTable">1</p>
//                       </TableCell>
//                       <TableCell>
//                         <Typography>Time</Typography>
//                         <p className="dataTable">102:22 pm</p>
//                       </TableCell>
//                       <TableCell>
//                         <Typography>Status</Typography>
//                         <p className=" dataTable2">Pending</p>
//                       </TableCell>
//                     </TableRow>
//                     {/* Add more rows here */}
//                   </TableBody>
//                 </Table>
//                 <Table className="AllTable">
//                   <TableBody>
//                     <TableRow>
//                       <TableCell>
//                         <Typography>Table No.</Typography>1
//                       </TableCell>
//                       <TableCell>
//                         <Typography>Time</Typography>102:22 pm
//                       </TableCell>
//                       <TableCell>
//                         <Typography>Status</Typography>1In progress
//                       </TableCell>
//                     </TableRow>
//                     {/* Add more rows here */}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Container>
//     </>
//   );
// };

// export default PageTable;
