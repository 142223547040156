import {
  TextField,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  AlertTitle,
  Snackbar,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Skeleton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImageUploading from 'react-images-uploading';
import { useEffect, useState } from 'react';
import axios from 'src/api/axios';
import { ADD_LANG, CATEGORIES } from 'src/constants/url';
import KitchenSection from '../KitchenSection';
import icon1 from '../../assets/default-category-icons/1.jpg';
import icon2 from '../../assets/default-category-icons/2.jpg';
import icon3 from '../../assets/default-category-icons/3.jpg';


const CreateCategory = ({ reload, setReload }) => {
  const [all, setAll] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [loadingLang, setLoadingLang] = useState(false);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesIcon, setImagesIcon] = useState([]);
  const [name ,setName] = useState('');
  const [empty, setEmpty] = useState('');
  const [error, setError] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const [kitchen_section, setkitchen_section] = useState('');

  const [DefaultIconValue, setDefaultIconValue] = useState();

  const handleChangeDefaultIconValue = (event) => {
    setDefaultIconValue(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage(false);
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const onChangeIcon = (imageList, addUpdateIndex) => {
    setImagesIcon(imageList);
  };
  const [value, setValue] = useState('');
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const CreateCategoryForm = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append(`name`, name);
    formData.append('staff_sections_id', kitchen_section);
    if (images.length != 0) {
      formData.append('image', images[0].file);
    }

    // switch (DefaultIconValue) {
    //   case '1':
    //     formData.append('icon', icon1);
    //     break;

    //   case '2':
    //     formData.append('icon', icon2);
    //     break;
    //   case '3':
    //     formData.append('icon', icon3);
    //     break;
    // }
    axios
      .post(CATEGORIES, formData, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        console.log(response)
        setMessage(true);
        setTimeout(() => {
          setReload(!reload);
        }, 1500);
        setOpen(false);
        setError(false);
        resetForm();
      })
      .catch(error => {
        // Handle errors
        let errArr = []
        Object.values(error.response.data.errors).forEach(e => {
          errArr.push(e[0])
        })
        setError(true);
        showErrorAlert(errArr)
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          console.log('Response status:', error.response.status);
          console.log('Response data:', error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Request was made but no response received:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error setting up the request:', error.message);
        }
        console.log('Error config:', error.config);
      })
      // .catch((error) => {
      //   setError(true);
      //   showErrorAlert(error.response.data.message);
        
      //   setEmpty('please add Staff section then try to add category');
      // })
      .finally(() => {
        setLoading(false);
      });
  };
  const resetForm = () => {
    setName('');
    setkitchen_section('');
    setImages([]);
    setImagesIcon([]);
    setError(false);
  };

  return (
    <>

      <Button variant="contained" onClick={handleClickOpen} className="btn-add">
        <AddIcon /> New Category
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create Category</DialogTitle>

        <DialogContent>
          <>
            {error ? (
              <Alert severity="error" className="mb-4">
                <AlertTitle>Error</AlertTitle>
                {erroralert.map((e,i)=>{
                  return(
                    <p style={{marginBottom :"5px"}} key={i}>{e}</p>
                  )
                })}
                {kitchen_section != null ? '' : empty}
              </Alert>
            ) : (
              ''
            )}
            <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
              {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                <div className="upload__image-wrapper position-relative mb-3">
                  <button
                    className="btn-add w-100 d-block text-white border-0 py-2"
                    style={isDragging ? { color: 'red' } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                    type="button"
                  >
                    upload Image
                  </button>
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image['data_url']} alt="" width="100" />
                      <div className="image-item__btn-wrapper">
                        <button
                          onClick={() => onImageRemove(index)}
                          className="bg-danger border-0 text-white position-absolute bottom-0 end-0"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
            {/* <Box sx={{ my: 2 }}>
              <FormControl>
                <FormLabel sx={{ color: '#000' }}>Or choose Icon from defaults</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={DefaultIconValue}
                  onChange={handleChangeDefaultIconValue}
                >
                  <FormControlLabel value="1" control={<Radio />} label={<img src={icon1} alt="icon" />} />
                  <FormControlLabel
                    sx={{ mx: 1 }}
                    value="2"
                    control={<Radio />}
                    label={<img src={icon2} alt="icon" />}
                  />
                  <FormControlLabel
                    sx={{ mx: 1 }}
                    value="3"
                    control={<Radio />}
                    label={<img src={icon3} alt="icon" />}
                  />
                </RadioGroup>
              </FormControl>

            </Box> */}
            {loadingLang ? <Skeleton animation="wave"  width="300px" /> :
            <>
            {
              <TextField
                id="filled-basic"
                label="Category Name"
                value={name}
                variant="filled"
                fullWidth
                onChange={(e) => setName(e.target.value)}
                className="mb-2"
              />
            }
            </>
            }
            <KitchenSection kitchen_section={kitchen_section} setkitchen_section={setkitchen_section} />
          </>
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={CreateCategoryForm}>
              Save
            </Button>
          )}

          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' , overflowX : "hidden"  }}

        >
          success! Category Added Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateCategory;
