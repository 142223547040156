import { Outlet, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { USER_AUTH } from './constants/url';
import axios from 'src/api/axios';

const PrivateRoutes = () => {
  const [userauth, setUserAuth] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {        
          const response = await axios.get(USER_AUTH , {
            headers : {
              Accept: 'application/json',
            }
          });
          if (response.status === 200) {
            setUserAuth(response.data.data);
        }
      } catch (error) {
        navigate('/login');
      }
    };

    fetchUserInfo();
  }, [navigate]);

  return <Outlet/>;
};

export default PrivateRoutes;
