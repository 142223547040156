
import Button from '@mui/material/Button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useState } from 'react';
import axios from 'src/api/axios';
import { USER } from 'src/constants/url';
const DeleteUser = ({setLoading1 ,  FetchUsers, reload, setReload, id }) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };

    const DeleteCategorySubmit = async () => {
        setLoading(true);
        await axios
            .delete(`${USER}/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setTimeout(() => {
                        setReload(!reload)
                    }, 1500);
                    setLoading1(true)
                    FetchUsers()
                    setOpen(false);
                    setMessage(true)

                }
            })
            .catch((error) => {
                console.log(error.response.data.message);

            });
        setLoading(false)
    }

    return (
        <>
           
           <Button variant="contained" onClick={handleClickOpen} className="bg-danger border-0 text-white ms-2">
                <DeleteOutlineOutlinedIcon />
            </Button>
       
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent >
                    <DialogContentText id="alert-dialog-description" > Are you sure to delete this user?  </DialogContentText>
                </DialogContent>
                <DialogActions className='mb-4'>
                    {loading ? <Button className="bg-danger text-white" > <CircularProgress style={{ color: '#fff' }} size={30} /></Button> :
                        <Button className="bg-danger text-white fs-6" onClick={DeleteCategorySubmit}>Yes</Button>
                    }
                    <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">No</Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={message} autoHideDuration={2000} onClose={handleCloseMessage} className="ic">
                <Alert onClose={handleCloseMessage} severity="success" sx={{ width: '100%' }} style={{ background: 'rgb(46, 125, 50)', color: '#fff' , overflowX : "hidden"  }}
>
                    success! User Deleted Successfully
                </Alert>
            </Snackbar>
        </>
    )
}

export default DeleteUser