import { Helmet } from 'react-helmet-async';
import { Typography, Container, Grid, Skeleton, Stack, Divider } from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'src/api/axios';
import { ABOUT, STORAGE } from 'src/constants/url';
import '../custom.css'
// import { Link } from 'react-router-dom';
import TitleIcon from '@mui/icons-material/Title';
import DescriptionIcon from '@mui/icons-material/Description';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import WifiIcon from '@mui/icons-material/Wifi';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Icon } from '@iconify/react';
import UpdateAbout from './UpdateAbout';
import { CloseRounded, EmailOutlined, HourglassBottomOutlined, HourglassEmpty } from '@mui/icons-material';
import { ClockPicker } from '@mui/lab';

const AboutPage = () => {
  const roles = localStorage.getItem('permissions');
  const [info, setInfo] = useState('');
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const FetchInfoAbout = async () => {
    await axios
      .get(ABOUT, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.status === 401) {
          navigate('/login');
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    FetchInfoAbout();
  }, [reload]);

  return (
    <>
      <Helmet>
        <title> About | Fenice Palas </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            About Fenice Palas
          </Typography>
        </Stack>
        {loading ? (
          <></>
        ) : info != null ? (
          <Grid container spacing={3} className="about-grid">
            <Grid item xs={12}>
              <Typography variant="h6">
                <TitleIcon /> Title: {info.title}{' '}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">
                <DescriptionIcon /> Bio: {info.bio}{' '}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                < EmailOutlined /> Email: {info.email}{' '}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                <HourglassBottomOutlined /> Open At: {info.open}{' '}
              </Typography>
            </Grid>
            <Grid item xs={12} /* sm={4} */ md={6}>
              <Typography variant="h6">
                <LocationOnIcon /> Address: {info.address}{' '}
              </Typography>
            </Grid>
            <Grid item xs={12} /* sm={4} */ md={6}>
              <Typography variant="h6">
                <CallIcon /> Telephone:{info.tel}
              </Typography>
            </Grid>
            <Grid item xs={12} /* sm={4} */ md={6}>
              <Typography variant="h6">
                <FacebookIcon /> Facebook: {info.facebook}
              </Typography>
            </Grid>
            <Grid item xs={12} /* sm={4} */ md={6}>
              <Typography variant="h6">
                <InstagramIcon /> Instagram: {info.instagram}
              </Typography>
            </Grid>
            <Grid item xs={12} /* sm={4} */ md={6}>
              <Typography variant="h6">
                <YouTubeIcon /> Youtube: {info.youtube}
              </Typography>
            </Grid>
            <Grid item xs={12}>
      <Typography variant="h6">Images:</Typography>
      <Stack direction="row" spacing={2}>
        <div className="images-container-about-section">
      {info.images &&
        info.images.map((image, index) => (
          <img className='image-in-about' key={index} src={`${STORAGE}/${image.image}`} alt={`Image ${index}`} style={{ maxWidth: '100%', height: 'auto' }} />
          ))}
          </div>
      </Stack>  
      </Grid>
            <Grid item xs={12}>
              
            </Grid>
            <Grid item xs={12}>
              
              <Divider />
            </Grid>
            {/* <Grid item xs={12}>
              {roles.includes('update-About') && <UpdateAbout i={info} setReload={setReload} reload={reload} />}
            </Grid> */}
            <div className=" m-4 ">
              {roles.includes('update-About') && <UpdateAbout i={info} setReload={setReload} reload={reload} />}
            </div>
          </Grid>
        ) : (
          <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
            {' '}
            NO information{' '}
          </Typography>
        )}
      </Container>
    </>
  );
};

export default AboutPage;
