
// export const BaseUrl = 'https://qr.zucodia.com/api';
// export const BaseUrl = "https://alzero1store.com";
// export const STORAGE = "https://alzero1store.com";
// export const BaseUrl = `https://api-smartorder.next-ai.pro` ;
// export const STORAGE = `https://api-smartorder.next-ai.pro`;
// export const BaseUrl = `https://api.smart-order-fenice.losyro.com` ;
export const BaseUrl = `https://api-smartorder.next-ai.pro` ;
export const STORAGE = `https://api-smartorder.next-ai.pro` ;
export const LOGIN = `${BaseUrl}/api/login`;
export const LOGOUT = `${BaseUrl}/api/logout`;
export const TABLES = `${BaseUrl}/api/table`;
export const CATEGORIES = `${BaseUrl}/api/category`;
export const KITCHEN_SECTION = `${BaseUrl}/api/staffSection`;
export const ITEMS = `${BaseUrl}/api/item`;
export const PACKAGES = `${BaseUrl}/api/package`;
export const USER = `${BaseUrl}/api/user`;
export const PERIOD_LOGIN = `${BaseUrl}/api/jwt_TTL`;
export const TABLE = `${BaseUrl}/api/table`;
export const DeleteTABLE = `${BaseUrl}/api/deleteLastTable`;
export const ResetTABLE = `${BaseUrl}/api/resetTable`;
export const TABLESTATUS = `${BaseUrl}/api/tableStatus`;
export const ABOUT = `${BaseUrl}/api/about`;
export const DELETE_ABOUT_IMAGE = `${BaseUrl}/api/about/image/`;
export const USER_AUTH = `${BaseUrl}/api/me`;
export const ROLES = `${BaseUrl}/api/roles`;
export const ORDERS = `${BaseUrl}/api/order`;
export const NOTIFICATIONS = `${BaseUrl}/api/notification`;
export const refreshToken = `${BaseUrl}/api/refresh`;
export const AVILABLE_LANG = `${BaseUrl}/api/avaliableLanguages`;
export const ADD_LANG = `${BaseUrl}/api/translations_languages`;
export const CURRENCY = `${BaseUrl}/api/currency`;
export const DefaultCurrency = `${BaseUrl}/api/setDefault`;
export const TOPTEN = `${BaseUrl}/api/topTenItems`;
export const TOPDailyItems = `${BaseUrl}/api/topDailyItems`;
export const DailyRevenue = `${BaseUrl}/api/revenue`;
export const TOPTENPackages = `${BaseUrl}/api/topTenPackages`;
export const TopDailyPackages = `${BaseUrl}/api/topDailyPackages`
export const updateFcmToken = `${BaseUrl}/api/update/fcm?_method=PUT`;
export const Merging = `${BaseUrl}/api/agreeToMerge`;
export const UpdateStatusProgress = `${BaseUrl}/api/makeInProgress`
export const UpdateStatusPinding = `${BaseUrl}/api/makePending`
export const UpdateStatusComplete = `${BaseUrl}/api/makeOrderComplete`
export const DeleteOrder = `${BaseUrl}/api/deleteOrder`
export const DeleteNoti = `${BaseUrl}/api/deleteNotification`
export const RejectMerge = `${BaseUrl}/api/rejectMerge`
export const archiveNotification = `${BaseUrl}/api/archiveNotification`
export const ArchiveCont = `${BaseUrl}/api/history`
export const ArchiveContOrders = `${BaseUrl}/api/archive`
export const ArchiveContOrdersDetails = `${BaseUrl}/api/orderDetails`
export const WaiterArchive = `${BaseUrl}/api/waiterAchivement`
export const DestroyOrders = `${BaseUrl}/api/destroyOrder`
export const DestroyNotifications = `${BaseUrl}/api/destroyNotification`
export const MergedNotifications = `${BaseUrl}/api/mergedNotifications`
export const EndSessions = `${BaseUrl}/api/logoutUser`
export const TopFiveItems = `${BaseUrl}/api/topFiveItems`
export const StatisticsAnalytics = `${BaseUrl}/api/statistics`
export const SalesAnalytics = `${BaseUrl}/api/salesSummary`
export const OrderSummary = `${BaseUrl}/api/ordersSummary`
export const getQr = `${BaseUrl}/api/get-table-qrs`
export const RegenerateQr = `${BaseUrl}/api/updateQr`