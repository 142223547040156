import React, { useEffect, useState } from 'react'; // Import useState

import axios from 'axios'
import './custom.css'
import { Merging, RejectMerge } from 'src/constants/url'

function MerNotificaMerge({ noti , orderNumber , fetchOrders}) {
  // Use state to manage the notifications
  const [notifications, setNotifications] = useState(noti);
  let roles = localStorage.getItem("role_name");
  const sendMerge = async (mergeNoti) => {
    try {
      const response = await axios.post(Merging, {
        order_id : mergeNoti.body,
      table_number : mergeNoti.table_id
      }, {
        headers: {
          Accept: 'application/json',
        }
      });
      if(response.status === 200) {
        fetchOrders(localStorage.getItem("index_table"))
      }
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification !== mergeNoti)
      );
    } catch (error) {
      console.error(error);
    }
  }
   const deletNoti = (deletedNoti) => {
    setNotifications((prevNotifications) =>
    prevNotifications.filter((notification) => notification !== deletedNoti)
  );
   }
  useEffect(() => {
    setNotifications(noti);
  }, [noti]);
    const rejectmerge = async(mergeNoti) =>{
    await axios.post(RejectMerge , {
      order_id : mergeNoti.body,
      table_number : mergeNoti.table_id
    } , {
      headers : {
          Accept: 'application/json',
      }
    }).then(res => {
    }).catch(error => {
      console.log(error)
    })
  }
  return (
    <>{ roles.toLocaleLowerCase() === "chef" && notifications.length > 0 ?
    <div className="noti-container">
      <div className="cont">
        {notifications.map((e, i) => { // Map over notifications
          return (
            <div key={i} className={`notification-merge ${e.noti}`}>
              <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                <g clipPath="url(#clip0_780_17169)">
                  <path d="M13.5 25.71C13.9724 25.6991 14.4258 25.5215 14.7799 25.2086C15.1341 24.8956 15.3661 24.4675 15.435 24H11.49C11.5608 24.4802 11.8037 24.9184 12.1735 25.2329C12.5432 25.5475 13.0146 25.717 13.5 25.71Z" fill="#CDA34F"/>
                  <path d="M24.6375 21.0975L24.3825 20.8725C23.659 20.228 23.0258 19.4888 22.5 18.675C21.9256 17.552 21.5814 16.3255 21.4875 15.0675V11.3625C21.4844 10.9125 21.4443 10.4635 21.3675 10.02C20.0971 9.75893 18.9559 9.06681 18.1372 8.06088C17.3185 7.05496 16.8726 5.79702 16.875 4.50005C16.875 4.34255 16.875 4.18505 16.875 4.02755C16.0919 3.64219 15.2506 3.38878 14.385 3.27755V2.33255C14.385 2.067 14.2795 1.81233 14.0917 1.62456C13.9039 1.43679 13.6493 1.3313 13.3837 1.3313C13.1182 1.3313 12.8635 1.43679 12.6757 1.62456C12.488 1.81233 12.3825 2.067 12.3825 2.33255V3.31505C10.4443 3.58846 8.67041 4.55386 7.38839 6.033C6.10638 7.51214 5.40277 9.40515 5.40748 11.3625V15.0675C5.31354 16.3255 4.96931 17.552 4.39498 18.675C3.87814 19.4868 3.25512 20.2259 2.54248 20.8725L2.28748 21.0975V23.2125H24.6375V21.0975Z" fill="#CDA34F"/>
                  <path d="M22.5 8.25C24.5711 8.25 26.25 6.57107 26.25 4.5C26.25 2.42893 24.5711 0.75 22.5 0.75C20.4289 0.75 18.75 2.42893 18.75 4.5C18.75 6.57107 20.4289 8.25 22.5 8.25Z" fill="#CDA34F"/>
                </g>
                <defs>
                  <clipPath id="clip0_780_17169">
                  <rect width="27" height="27" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              </div>
              <p className="new-order">{e.tittle} </p>
              <button className="merge" onClick={() => sendMerge(e)}>Merge to Table’s order</button>
              <div className='orderOption'>
              <button className="back" onClick={()=>rejectmerge(e)}>Back To Order</button>
                </div>
            </div>
          )
        })}
      </div>
    </div>
    : ""
  }
    </>
  )
}

export default MerNotificaMerge;
