import React, { useEffect, useState } from 'react';
import axios from 'src/api/axios';
import { BaseUrl, RegenerateQr, getQr } from 'src/constants/url';
import './qr.css';
import Iconify from 'src/components/iconify';
import { Spinner } from 'react-bootstrap';

const Qr = () => {
  const [qr, setQr] = useState([]);
  const [sure , setSure] = useState(0)
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const handleRegenClick = (index) => {
    setSelectedItemIndex(index)
  };
  const getQrCodes = async () => {
    axios
      .get(getQr, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res)
          setQr(res.data.data);
        }
      });
  };
  const RegenerateQrCode = async (tableNumber) => {
    setSure(1)
    await axios.post(`${RegenerateQr}/${tableNumber}`,{},{
      headers: {
        Accept: 'application/json',
      },
    }).then(res => {
      if(res.status === 200) {
        setSure(0)
        handleRegenClick(0)
      }
    }).catch(e =>{
      console.log(e)
    })
  }
  useEffect(() => {
    getQrCodes();
  }, [sure]);

  const handlePrint = (imgSrc) => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`<img src="${imgSrc}" alt="" />`);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="cards-qr-container">
      {qr.map((e) => {
        let imgSrc = e.qr;
        // console.log(imgSrc)
        // console.log(e.qr)
        return (
          <div className="card-qr" key={e.table_number}>
            <span className="table-number-qr">{e.table_number}</span>
            <div className='img-qr'>
              <img src={`${imgSrc}`} alt="" />
              <div className={selectedItemIndex === e.table_number ? "img-qr-layer" : "img-qr-layer d-none"}>
                {
                  sure ? <Spinner animation="border" style={{width : "40px" , height : "40px" , color : "rgb(0, 182, 61)"}}/> : <>
                    <Iconify onClick={() => handleRegenClick(0)} icon={'akar-icons:circle-x-fill'} width={30} height={30} />
                    <span className='sure-qr'>Are you sure ?</span>
                    <Iconify onClick={() => {RegenerateQrCode(e.table_number)}} icon={"icon-park-solid:check-one"} width={30} height={30} />
                  </>
                }
              </div>
            </div>
            <div className="buttons-container-qr">
              <span onClick={() => handleRegenClick(e.table_number)} className="btn-qr regen-qr">
                <Iconify icon={'mdi:compost'} width={22} height={22} />
              </span>
              <span onClick={() => handlePrint(imgSrc)} className="btn-qr print-qr">
                <Iconify icon={'mdi:printer'} width={22} height={22} />
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Qr;
