import { useEffect, useState } from 'react';
import './table.css'
import { BaseUrl } from 'src/constants/url';
function OrderCard ({data, setActiveCard, activeCard, onCardClick , handleAddItem , classOrder}) {
    const [active , setActive] = useState(false) 
    const isActive = activeCard === data.name;
    return(
            <div className={`${classOrder} ${isActive ? 'active-card' : ''}`} onClick={() => {setActiveCard(data.name); onCardClick([data.notes , data.name , data.quantity])}}>
            <img src={`${BaseUrl}${data.image}`} alt="" />
            <div className="all-togather" style={{position : "absolute"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 9 9" fill="none">
                <path d="M5.9 4.0625H7.37778C7.59559 4.0625 7.70448 4.0625 7.78767 4.01481C7.86086 3.97286 7.92032 3.90596 7.95761 3.82363C8 3.73004 8 3.60752 8 3.3625V1.7C8 1.45498 8 1.33246 7.95761 1.23888C7.92032 1.15656 7.86086 1.08963 7.78767 1.04768C7.70448 1 7.59559 1 7.37778 1H5.9C5.68218 1 5.57329 1 5.49011 1.04768C5.41692 1.08963 5.35746 1.15656 5.32017 1.23888C5.27778 1.33246 5.27778 1.45498 5.27778 1.7V3.3625C5.27778 3.60752 5.27778 3.73004 5.32017 3.82363C5.35746 3.90596 5.41692 3.97286 5.49011 4.01481C5.57329 4.0625 5.68218 4.0625 5.9 4.0625Z" fill="white"/>
                <path d="M6.3375 8H7.0375C7.25532 8 7.36421 8 7.44739 7.95231C7.52058 7.91036 7.58004 7.84346 7.61733 7.76113C7.65972 7.66754 7.65972 7.54504 7.65972 7.3V6.5125C7.65972 6.26746 7.65972 6.14496 7.61733 6.05137C7.58004 5.96904 7.52058 5.90214 7.44739 5.86019C7.36421 5.8125 7.25532 5.8125 7.0375 5.8125H6.3375C6.11968 5.8125 6.01079 5.8125 5.92761 5.86019C5.85442 5.90214 5.79496 5.96904 5.75767 6.05137C5.71528 6.14496 5.71528 6.26746 5.71528 6.5125V7.3C5.71528 7.54504 5.71528 7.66754 5.75767 7.76113C5.79496 7.84346 5.85442 7.91036 5.92761 7.95231C6.01079 8 6.11968 8 6.3375 8Z" fill="white"/>
                <path d="M2.55556 1V8M2.55556 1L4.11111 2.75M2.55556 1L1 2.75M5.9 4.0625H7.37778C7.59559 4.0625 7.70448 4.0625 7.78767 4.01481C7.86086 3.97286 7.92032 3.90596 7.95761 3.82362C8 3.73004 8 3.60752 8 3.3625V1.7C8 1.45498 8 1.33247 7.95761 1.23888C7.92032 1.15656 7.86086 1.08963 7.78767 1.04768C7.70448 1 7.59559 1 7.37778 1H5.9C5.68218 1 5.57329 1 5.49011 1.04768C5.41692 1.08963 5.35746 1.15656 5.32017 1.23888C5.27778 1.33247 5.27778 1.45498 5.27778 1.7V3.3625C5.27778 3.60752 5.27778 3.73004 5.32017 3.82362C5.35746 3.90596 5.41692 3.97286 5.49011 4.01481C5.57329 4.0625 5.68218 4.0625 5.9 4.0625ZM6.3375 8H7.0375C7.25532 8 7.36421 8 7.44739 7.95231C7.52058 7.91036 7.58004 7.84346 7.61733 7.76113C7.65972 7.66754 7.65972 7.54504 7.65972 7.3V6.5125C7.65972 6.26746 7.65972 6.14496 7.61733 6.05137C7.58004 5.96904 7.52058 5.90214 7.44739 5.86019C7.36421 5.8125 7.25532 5.8125 7.0375 5.8125H6.3375C6.11968 5.8125 6.01079 5.8125 5.92761 5.86019C5.85442 5.90214 5.79496 5.96904 5.75767 6.05137C5.71528 6.14496 5.71528 6.26746 5.71528 6.5125V7.3C5.71528 7.54504 5.71528 7.66754 5.75767 7.76113C5.79496 7.84346 5.85442 7.91036 5.92761 7.95231C6.01079 8 6.11968 8 6.3375 8Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p className={data.allTogether > 0 ? "amount-togather" : "d-none"}>{data.allTogether}</p>
            </div>
            <div className={data.notes.length === 0 ? "note op-0":"note"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <g clipPath="url(#clip0_776_13845)">
                    <path d="M3.57174 0.955078H12.2273C13.5102 0.955078 14.5598 2.00541 14.5598 3.28832V8.66134C14.5598 9.94499 13.5103 10.9953 12.2273 10.9953H11.7447L13.0617 14.3876C13.117 14.5308 13.0683 14.6929 12.9418 14.7809C12.8844 14.8223 12.8175 14.8419 12.7514 14.8419C12.6728 14.8419 12.5944 14.8136 12.5319 14.7598C12.5319 14.7598 8.2724 11.012 8.25932 10.9946H3.571C2.2866 10.9946 1.23701 9.94425 1.23701 8.6606V3.28832C1.23775 2.00541 2.28664 0.955078 3.57174 0.955078ZM3.70329 7.74768H12.2499V6.76204H3.70258V7.74768H3.70329ZM3.70329 5.03571H12.2499V4.05008H3.70258V5.03571H3.70329Z" fill="#A77C27"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_776_13845">
                    <rect width="13.8869" height="13.8869" fill="white" transform="matrix(-1 0 0 1 14.8419 0.955078)"/>
                    </clipPath>
                    </defs>
                </svg>
                    <span>{data.notes.length === 1 ? "" : data.notes.length}</span>
            </div>
            <div className="check-box">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 19 19" fill="none">
            <g clipPath="url(#clip0_779_16817)">
            <path d="M9.5 0C4.26153 0 0 4.26175 0 9.5C0 14.7382 4.26153 19 9.5 19C14.7385 19 19 14.7382 19 9.5C19 4.26175 14.7385 0 9.5 0ZM13.962 8.44242L9.55002 12.8544C9.06601 13.3384 8.28185 13.3384 7.79779 12.8544L5.24452 10.3011C4.76047 9.81725 4.76047 9.03272 5.24452 8.54885C5.72857 8.0648 6.5127 8.0648 6.99675 8.54885L8.67391 10.2258L12.2098 6.69012C12.6938 6.20606 13.4779 6.20606 13.962 6.69012C14.446 7.17402 14.446 7.95855 13.962 8.44242Z" fill="#03AB00"/>
            <path d="M13.962 8.44242L9.55002 12.8544C9.06601 13.3384 8.28185 13.3384 7.79779 12.8544L5.24452 10.3011C4.76047 9.81725 4.76047 9.03272 5.24452 8.54885C5.72857 8.0648 6.5127 8.0648 6.99675 8.54885L8.67391 10.2258L12.2098 6.69012C12.6938 6.20606 13.4779 6.20606 13.962 6.69012C14.446 7.17402 14.446 7.95855 13.962 8.44242Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_779_16817">
            <rect width="21" height="21" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            </div>
            <div className="text">
                <div className="amount">{data.quantity === 1 && classOrder === "card-order" ? "" :  data.quantity} {data.quantity === 1 ? "" : (<span>*</span>)}</div>
                <div className="type" style={{width : "120px" , marginTop : "5px"}}>{data.name}</div>
            </div>
        </div>
            )
}
export default OrderCard;
{/* <div className="text-together">
<div className="amount">{data.allTogether}  <span> *</span></div>
</div> */}