import { useState } from "react";
import {
    TextField,
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Alert,
    AlertTitle,
    Snackbar
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CURRENCY } from "src/constants/url";
import axios from "axios";
const CreateCurrency = ({ setLoading1 , FetchCurrency , reload, setReload }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currency, setCurrency] = useState('');
    const [rate, setRate] = useState('');
    const [error, setError] = useState(false);
    const [erroralert, showErrorAlert] = useState([]);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessage(false);
    };
    const CreateCurrencyForm = () => {
        setLoading(true);
        const data = {
            Currency: currency,
            exchange_rate: rate
        }
        axios
            .post(CURRENCY, data, {
                headers: {
                    Accept: 'application/json',
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setCurrency('');
                    setRate('');
                    setMessage(true);
                    setLoading1(true);
                    FetchCurrency()
                    setTimeout(() => {
                        setReload(!reload);
                    }, 1500);
                    setOpen(false);
                    setError(false);
                }
            })
            .catch((error) => {
                let errArr = []
                Object.values(error.response.data.errors).forEach(e => {
                errArr.push(e[0])
                })
                setError(true);
                showErrorAlert(errArr)
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Button variant="contained" onClick={handleClickOpen} className="btn-add">
                <AddIcon /> New Currency
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">Create Currency</DialogTitle>
                <DialogContent>
                    <>
                        {error ? (
                            <Alert severity="error" className="mb-4">
                                <AlertTitle>Error</AlertTitle>
                                {erroralert.map((e,i)=>{
                                    return(
                                        <p style={{marginBottom :"5px"}} key={i}>{e}</p>
                                    )
                                })}
                            </Alert>
                        ) : (
                            ''
                        )}
                        <TextField
                            id="filled-basic"
                            label="Currncy Name"
                            value={currency}
                            variant="filled"
                            fullWidth
                            onChange={(e) => setCurrency(e.target.value)}
                            className="mb-2"
                        />

                        <TextField
                            id="filled-basic"
                            label="Exchange Rate"
                            value={rate}
                            variant="filled"
                            fullWidth
                            onChange={(e) => setRate(e.target.value)}
                            className="mb-2"
                        />
                    </>
                </DialogContent>
                <DialogActions className="mb-4">
                    {loading ? (
                        <Button className=" btn-bg">
                            <CircularProgress style={{ color: '#fff' }} size={30} />
                        </Button>
                    ) : (
                        <Button className="btn-bg  text-white fs-6" onClick={CreateCurrencyForm}>
                            Save
                        </Button>
                    )}
                    <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                <Alert
                    onClose={handleCloseMessage}
                    severity="success"
                    sx={{ width: '100%' }}
                    style={{ background: 'rgb(46, 125, 50)', color: '#fff' , overflowX : "hidden"  }}
                >
                    success! Currency Added Successfully
                </Alert>
            </Snackbar>
        </>
    )
}

export default CreateCurrency