import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useEffect, useState } from 'react';
import axios from 'src/api/axios';
import {
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  //   DialogContentText,
  DialogTitle,
  Skeleton,
} from '@mui/material';
import ImageUploading from 'react-images-uploading';
import { ADD_LANG, CATEGORIES, STORAGE } from 'src/constants/url';
import KitchenSection from '../KitchenSection';

const UpdateCategory = ({ reload, setReload, id, i }) => {
  const [all, setAll] = useState([])
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingLang, setLoadingLang] = useState(false);
  const [message, setMessage] = useState(false);
  const [nameEn, setNameEn] = useState(i.name);
  const [images, setImages] = useState([]);
  const [imagesIcon, setImagesIcon] = useState([]);
  const [kitchen_section, setkitchen_section] = useState(i.staff_sections_id ? i.staff_sections_id : '');
  const [showImage, setshowImage] = useState(i.image);
  const [showImageIcon, setshowImageIcon] = useState(i.icon);


  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };
  const UpdateCategory = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append(`name`, nameEn);
    if (images.length != 0) {
      formData.append('image', images[0].file);
    }
    if (imagesIcon.length != 0) {
      formData.append('icon', imagesIcon[0].file);
    }
    formData.append('staff_sections_id', kitchen_section);

    await axios
      .post(`${CATEGORIES}/${id}?_method=PUT`, formData, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setMessage(true);
          setTimeout(() => {
            setReload(!reload);
          }, 1500);
          setOpen(false);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    setLoading(false);
  };
  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen} className="btn-bg border-0 text-white">
        <ModeEditOutlineOutlinedIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update Category</DialogTitle>
        <DialogContent>
          <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
            {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
              <div className="upload__image-wrapper position-relative mb-3">
                <button
                  className="btn-add w-100 d-block text-white border-0 py-2"
                  style={isDragging ? { color: 'red' } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                  type="button"
                >
                  upload Image
                </button>
                {imageList.length > 0 ? (
                  imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image['data_url']} alt="" width="100" />
                      <div className="image-item__btn-wrapper">
                        <button
                          onClick={() => onImageRemove(index)}
                          className="bg-danger border-0 text-white position-absolute bottom-0 end-0"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <img src={`${STORAGE}/${showImage}`} width="100%"  className="rounded-top" />
                )}
              </div>
            )}
          </ImageUploading>
          {loadingLang ? <Skeleton animation="wave" width="300px" /> :
            <>
              {
                <TextField
                  id="filled-basic"
                  label={`Category Name`}
                  value={nameEn}
                  // key={item.code}
                  variant="filled"
                  fullWidth
                  onChange={(e) => setNameEn(e.target.value)}
                  className="mb-2"
                />
              }
            </>
          }
          <KitchenSection kitchen_section={kitchen_section} setkitchen_section={setkitchen_section} />
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={UpdateCategory}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' , overflowX : "hidden"  }}

        >
          success! Category Updated Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default UpdateCategory;
