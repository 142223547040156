import { useState, useEffect } from 'react';
import axios from 'src/api/axios';
import { KITCHEN_SECTION } from 'src/constants/url';
import { InputLabel, MenuItem, FormControl, Select, LinearProgress, Skeleton } from '@mui/material';
const KitchenSection = (props) => {
  const [info, setInfo] = useState([]);
  const [infoLoaded, setInfoLoaded] = useState(false);

  const handleChange = (e) => {
    props.setkitchen_section(e.target.value);
  };
  const FetchKitchenSection = async () => {
    setInfoLoaded(false);
    await axios
      .get(KITCHEN_SECTION, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      })
      .finally(() => {
        setInfoLoaded(true);
      });
  };
  useEffect(() => {
    FetchKitchenSection();
  }, []);
  return !infoLoaded ? (
    <Skeleton width={'100%'} height={'5rem'} />
  ) : (
    <FormControl variant="filled" fullWidth className="mt-2">
      <InputLabel id="demo-simple-select-filled-label">Select Resturant Section</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={props.kitchen_section}
        onChange={handleChange}
        name="Staff"
      >
        {info.length > 0 ? (
          info.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {item.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem>No Staff Sections</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default KitchenSection;
