import { Helmet } from 'react-helmet-async';
import './archive.css'
import FixedSizeGrid from './BasicExampleDataGridPro';
import { Grid } from '@mui/material';
import axios from 'src/api/axios';
import { ArchiveContOrders, ArchiveContOrdersDetails } from 'src/constants/url';
import { useEffect, useState } from 'react';
import OrderArchived from './OrderArchived';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
const VISIBLE_FIELDS = ['name', 'rating', 'country', 'dateCreated', 'isAdmin'];
function Archive () {
    const navigate = useNavigate()
    const [data ,setData] = useState([])
    const [clickedOrder , setClickedOrder] = useState(0)
    const [loadData , setLoadData] = useState(false)
    const fetchOrdersDetails = async() => {
        await axios.post(`${ArchiveContOrdersDetails}/${clickedOrder}`,{},{
            headers : {
                Accept: 'application/json',
            }
        }).then(res => {
            if(res.status === 200) {
                setData(Object.values(res.data.data.items))
                setLoadData(false)
            }
        })
    }
    useEffect(()=>{
        fetchOrdersDetails()
    },[clickedOrder])
    return(
        <div className='archive-section' style={{overflow : "hidden"}}>
            <Helmet>
                <title>Archive | Fenice Palas</title>
            </Helmet>
            <div className="route-cont" style={{
                display : "flex",
            }}>
                <p className='p1 active-p'>Orders Archive</p>
                <p className='p3' onClick={() => navigate("/dashboard/archive/archive-notifications")}>Notifications Archive</p>
                <p className='p2' onClick={() => navigate("/dashboard/archive/archive-waiters")}>Waiters Archive</p>
            </div>
            <h2>Archived Orders</h2>
            <div className="order-archive-container">
            <Grid container item>
            <Grid item xs={12} lg={8} xl={8} md={8} sm={12} >
            <FixedSizeGrid clickedOrder={clickedOrder} setClickedOrder={setClickedOrder} setLoadData={setLoadData}/>
            </Grid>
            <Grid item xs={12} lg={4} xl={4} md={4} sm={12}>
            <div className="items-in-order" style={{width :"100%" , minHeight : "420px" , borderRadius : "10px"}}>
                <div className="title-details">Order Details <span>#{clickedOrder}</span></div>
                <hr className="colored" />
                <div className="oreders-container">
                    {
                        loadData ? <Spinner animation="grow" variant="warning" style={{backgroundColor : "#CDA34F" , width : "40px" , height : "40px" , marginTop : "40px"}} /> : data.length === 0 ? "There's No Items" : data.map((e,i) => {
                            return(
                                <OrderArchived item={e} key={i}/>
                            )
                        })
                    }
                </div>
            </div>
            </Grid>
            </Grid>
            </div>
        </div>
    )
}
export default Archive;