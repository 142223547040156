import { Container, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom";
import { CURRENCY, DefaultCurrency } from "src/constants/url";
import CreateCurrency from "./CreateCurrency";
import UpdateCurrency from "./UpdateCurrnecy";
import DeleteCurrency from "./DeleteCurrency";
import { Spinner } from "react-bootstrap";


const CurrencyPage = () => {
    const roles = localStorage.getItem('permissions');
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();
    const [loadingCheck, setLoadingCheck] = useState(false);
    const FetchCurrency = async () => {
        setLoading(true);
        await axios
            .get(CURRENCY, {
                headers: {
                    Accept: 'application/json',
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setInfo(response.data.data);
                    response.data.data.forEach(e => {
                        if(e.isDefault === 1)
                        setSelectedItemId(e.id)
                    })
                }
            })
            .catch((error) => {
                if (error.status === 401) {
                    navigate('/login');
                }
            })
            .catch(error => {
                // Handle errors
                if (error.response) {
                  // The request was made and the server responded with a status code that falls out of the range of 2xx
                  console.log('Response status:', error.response.status);
                  console.log('Response data:', error.response.data);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log('Request was made but no response received:', error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error setting up the request:', error.message);
                }
                console.log('Error config:', error.config);
              });;
        setLoading(false);
    };
    useEffect(() => {
        FetchCurrency();
    }, [reload]);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [loadingItemId, setLoadingItemId] = useState(null);
  // Function to handle checkbox click
  const handleCheckboxClick = (item) => {
    setLoadingItemId(item.id);
    // setLoadingCheck(true)
    axios.post(DefaultCurrency ,{currency_id : item.id}, {
        headers : {
            Accept: 'application/json',

        }
    }).then(res => {
        if(res.status === 200){
            setSelectedItemId(item.id); // Update the checkbox state
        }
    }).finally(()=>  setLoadingItemId(null))
  };
    return (
        <>
            <Helmet>
                <title> Currency | Fenice Palas </title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Currency
                    </Typography>
                    <CreateCurrency reload={reload} setReload={setReload} setLoading1={setLoading} FetchCurrency={FetchCurrency} />
                </Stack>
                {loading ? <></> :
                    <>
                        {info.length > 0 ? (
                            <TableContainer
                                component={Paper}
                                sx={{
                                    '& .MuiPaper-root ': {
                                        overflowX: 'scroll',
                                    },
                                }}
                            >
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">ID</TableCell>
                                            <TableCell align="center">Currency Name</TableCell>
                                            <TableCell align="center">Exchange Rate</TableCell>
                                            <TableCell align="center">Edit & Delete</TableCell>
                                            <TableCell align="center">Default Currency</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {info.map((item) => (
                                            <TableRow key={item.id} id={item.id}>
                                                <TableCell align="center">{item.id}</TableCell>
                                                <TableCell align="center">{item.currency}</TableCell>
                                                <TableCell align="center">{item.exchange_rate}</TableCell>
                                                <TableCell align="center" style={{display : "flex", justifyContent : "center" , flexWrap : "wrap" , gap : "20px"}}>
                                                    {roles.includes('update-Item') && (
                                                    <UpdateCurrency id={item.id} i={item} setReload={setReload} reload={reload} />
                                                    )}
                                                    {roles.includes('delete-Item') && (
                                                    <DeleteCurrency id={item.id} setReload={setReload} reload={reload} />
                                                    )}
                                                </TableCell>
                                                <TableCell align="center" width={"30px"}>
                                                {loadingItemId === item.id ? (
                                                <Spinner animation="grow" variant="primary" style={{width : "25px" , height : "25px"}} />
                                                ) : (
                                                <input
                                                    type="checkbox"
                                                    name=""
                                                    id=""
                                                    style={{ width: "20px", height: "20px" }}
                                                    checked={selectedItemId === item.id}
                                                    onChange={() => handleCheckboxClick(item)}
                                                />
                                                )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
                                No currency yet
                            </Typography>
                        )}
                    </>
                }
            </Container>
        </>
    )
}

export default CurrencyPage