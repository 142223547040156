// component

import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const roles = localStorage.getItem('role_name');

const navConfig =
  roles === 'SuperAdmin'
    ? [
      {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon('bi:pie-chart-fill'),
      },
      {
        title: 'QR Codes',
        path: '/dashboard/QR-Codes',
        icon: getIcon('ion:qr-code'),
      },
      {
        title: 'about',
        path: '/dashboard/about',
        icon: getIcon('mdi:about-circle-outline'),
      },
      {
        title: 'categories',
        path: '/dashboard/categories',
        icon: getIcon('bxs:category'),
      },
      {
        title: 'items',
        path: '/dashboard/items',
        icon: getIcon('material-symbols:format-list-bulleted'),
      },
      {
        title: 'Packages',
        path: '/dashboard/packages',
        icon: getIcon('iconoir:packages'),
      },
      {
        title: 'Resturant section',
        path: '/dashboard/staff-section',
        icon: getIcon('tabler:tools-kitchen-2'),
      },
      {
        title: 'users',
        path: '/dashboard/users',
        icon: getIcon('clarity:group-solid'),
      },
      {
        title: 'tables',
        path: '/dashboard/tables',
        icon: getIcon('ri:table-fill'),
      },
      {
        title: 'orders',
        path: '/dashboard/orders',
        icon: getIcon('bxs:dish'),
      },
      {
        title: 'archive',
        path: '/dashboard/archive',
        icon: getIcon('tabler:archive-filled'),
      },
      {
        title: 'Currency',
        path: '/dashboard/currency',
        icon: getIcon('grommet-icons:currency'),
      },
    ]
    : roles === 'Captain'
    ? [
      {
        title: 'categories',
        path: '/dashboard/categories',
        icon: getIcon('bxs:category'),
      },
      {
        title: 'items',
        path: '/dashboard/items',
        icon: getIcon('material-symbols:format-list-bulleted'),
      },
      {
        title: 'Packages',
        path: '/dashboard/packages',
        icon: getIcon('iconoir:packages'),
      },
      {
        title: 'Resturant sections',
        path: '/dashboard/staff-section',
        icon: getIcon('tabler:tools-kitchen-2'),
      },
      {
        title: 'tables',
        path: '/dashboard/tables',
        icon: getIcon('ri:table-fill'),
      },
      {
        title: 'orders',
        path: '/dashboard/orders',
        icon: getIcon('bxs:dish'),
      },
      {
        title: 'archive',
        path: '/dashboard/archive',
        icon: getIcon('tabler:archive-filled'),
      },
    ]
    : roles === 'Chef'
    ? [
      {
        title: 'tables',
        path: '/dashboard/tables',
        icon: getIcon('ri:table-fill'),
      },
      {
        title: 'orders',
        path: '/dashboard/orders',
        icon: getIcon('bxs:dish'),
      },
    ]
    : roles === 'Waiter'
    ? [
      {
        title: 'tables',
        path: '/dashboard/tables',
        icon: getIcon('ri:table-fill'),
      },
      {
        title: 'orders',
        path: '/dashboard/orders',
        icon: getIcon('bxs:dish'),
      },
    ]
    : [{}]; // Default empty array for unknown roles

export default navConfig;