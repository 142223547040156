import {
  TextField,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  //   DialogContentText,
  DialogTitle,
  Alert,
  AlertTitle,
  Snackbar,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImageUploading from 'react-images-uploading';
import { useEffect, useState } from 'react';
import axios from 'src/api/axios';
import CategorySection from '../CategorySection';
import { ADD_LANG, ITEMS, PACKAGES } from 'src/constants/url';
import ItemSelect from '../ItemSelect';

const CreatePackage = ({ reload, setReload }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(''); // new state variable for name
  const [price, setPrice] = useState('');
  const [offer_price, setOfferPrice] = useState('');
  const [images, setImages] = useState([]);
  const [category_section, setcategory_section] = useState('');
  const [error, setError] = useState(false);
  const [empty, setEmpty] = useState('');
  const [erroralert, showErrorAlert] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };


const [selectedItems, setSelectedItems] = useState([])
const CreateItemForm = () => {
setLoading(true);
const formData = new FormData();
formData.append('name', name);
formData.append('price', price);
for (var i = 0; i < selectedItems.length; i++) {
formData.append('item_id[]', selectedItems[i].id);
}
if (offer_price != null) {
formData.append('offer_price', offer_price);
}

if (images.length != 0) {
formData.append('image', images[0].file);
}


axios
.post(PACKAGES, formData, {
headers: {
Accept: 'application/json',
},
})
.then((response) => {
setMessage(true);
setTimeout(() => {
setReload(!reload);
}, 1500);
setOpen(false);
setError(false);
})

.catch((error) => {
  let errArr = []
  Object.values(error.response.data.errors).forEach(e => {
    errArr.push(e[0])
  })
  setError(true);
  showErrorAlert(errArr)
setEmpty('please add category then try to add item');
})
.finally(() => {
setLoading(false);
setName(''); // reset name instead of name[en] and name[fr]
setPrice('');
setOfferPrice('');
setImages([]);
setcategory_section('');

setSelectedItems([])
});
};
return (
<>
<Button variant="contained" onClick={handleClickOpen} className="btn-add">
<AddIcon /> New Package
</Button>

<Dialog
open={open}
onClose={handleClose}
aria-labelledby="alert-dialog-title"
aria-describedby="alert-dialog-description"
>
<DialogTitle id="alert-dialog-title">Create Package</DialogTitle>

<DialogContent>
<>
{error ? (
<Alert severity="error" className="mb-4">
<AlertTitle>Error</AlertTitle>
{erroralert.map((e,i)=>{
                  return(
                    <p style={{marginBottom :"5px"}} key={i}>{e}</p>
                  )
                })}
{category_section != null ? '' : empty}
</Alert>
) : (
''
)}
<ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
{({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
<div className="upload__image-wrapper position-relative mb-3">
<button
className="btn-add w-100 d-block text-white border-0 py-2"
style={isDragging ? { color: 'red' } : undefined}
onClick={onImageUpload}
{...dragProps}
type="button"
>
upload Image
</button>
{imageList.map((image, index) => (
<div key={index} className="image-item">
<img src={image['data_url']} alt="" width="100" />
<div className="image-item__btn-wrapper">
<button
onClick={() => onImageRemove(index)}
className="bg-danger border-0 text-white position-absolute bottom-0 end-0"
>
Remove
</button>
</div>
</div>
))}
</div>
)}
</ImageUploading>

<TextField // new input field for name
  id="filled-basic"
  label="Package Name"
  value={name}
  variant="filled"
  fullWidth
  onChange={(e) => setName(e.target.value)}
  className="mb-2"
/>

<TextField
id="filled-basic"
label="Package Price"
value={price}
type="number"
variant="filled"
fullWidth
onChange={(e) => setPrice(e.target.value)}
className="mb-2"
/>

<TextField
id="filled-basic"
label="Package Offer Price"
value={offer_price}
type="number"
variant="filled"
fullWidth
onChange={(e) => setOfferPrice(e.target.value)}
className="mb-2"
/>


<ItemSelect itemsSelected={selectedItems} setItems={setSelectedItems}/>
</>
</DialogContent>
<DialogActions className="mb-4">
{loading ? (
<Button className=" btn-bg">
<CircularProgress style={{ color: '#fff' }} size={30} />
</Button>
) : (
<Button className="btn-bg  text-white fs-6" onClick={CreateItemForm}>
Save
</Button>
)}

<Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
Close
</Button>
</DialogActions>
</Dialog>

<Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
<Alert
onClose={handleCloseMessage}
severity="success"
sx={{ width: '100%' }}
style={{ background: 'rgb(46, 125, 50)', color: '#fff' , overflowX : "hidden"  }}

>
success! Category Added Successfully
</Alert>
</Snackbar>
</>
);
};

export default CreatePackage;
