import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { updateFcmToken } from "./constants/url";

const firebaseConfig = {
  apiKey: "AIzaSyDQQ5O4bLkqY2JGLnFQXYytnY3QM7PrW4c",
  authDomain: "smartorderwebsite.firebaseapp.com",
  projectId: "smartorderwebsite",
  storageBucket: "smartorderwebsite.appspot.com",
  messagingSenderId: "657852404074",
  appId: "1:657852404074:web:46b153e2ff000031ae5513",
  measurementId: "G-FTXV5C84WF"
};

initializeApp(firebaseConfig); 

const messaging = getMessaging();

export const requestForToken = async () => {
  console.log("hi")
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: "BGnulLzQLPXIHirPZgS3grbjFDiAb2XqxqpWI1um1LkYL4MSymu-cf5C4nrCaqoUJmjgwe-oNg27xJd3QTKwufk"
    });
    if (currentToken) {
        axios.post(
          updateFcmToken,
          { fcm_token: currentToken },
          {
            headers: {
              Accept: "application/json",
            },
            withCredentials : true
          }
          )
          .then((response) => {
            console.log(response);
          })
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
      if(payload !== null){
        if(payload.notification.title === "EndSession") {
          localStorage.removeItem("userName");
          localStorage.removeItem("uesrEmail");
          localStorage.removeItem('role_name');
          localStorage.removeItem('permissions');
          localStorage.removeItem('role_name');
          localStorage.removeItem('index_table');
          localStorage.removeItem('Table_Noti_Type')
          location.replace("/login");
        }
      }
    });
  }).then(payload => {
    return payload
  })
