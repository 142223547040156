import { useState, useEffect } from 'react';
import axios from 'src/api/axios';
import { ITEMS } from 'src/constants/url';
import { InputLabel, MenuItem, FormControl, Select, Skeleton, OutlinedInput, Stack, Chip, FormGroup, FormControlLabel , Checkbox} from '@mui/material';
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { it } from 'date-fns/locale';
import './custom.css';

const ItemSelect = (props) => {
  // console.log(props)
  const [info, setInfo] = useState([]);
  const [infoLoaded, setInfoLoaded] = useState(false);

  const handleChange = (e, d) => {
      // console.log("on click", d)
      if(e.target.checked){
          const temp =  props.itemsSelected.filter(item => item.id == d.id)
          // console.log("temp", temp)
          if(temp.length == 0){
              props.setItems([...props.itemsSelected, d]);
          }
      }else{
          const temp = props.itemsSelected.filter(item => item.id != d.id)
          props.setItems(temp)
      }
  };
  const FetchCategorySection = async () => {
    await axios
      .get(ITEMS   , {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      })
      .finally(() => {
        setInfoLoaded(true);
      });
  };
  const searchFunction = (e)=> {
    let childs = e.target.parentElement.childNodes;
    let searchValue = e.target.value.toLowerCase();
    for(let i = 1 ; i < childs.length ; i++) {
        if(childs[i].innerText.toLowerCase().indexOf(searchValue) ===  -1){
          childs[i].style.display = "none";
        }else {
          childs[i].style.display = "block";
          childs[i].style.margin = "2px 10px"
        } 
      }
  }
  useEffect(() => {
    FetchCategorySection();
  }, []);
  const checkExistance = (arr, id) => {
      const temp = arr.filter(item => item.id == id)
      return temp.length > 0
  }
  return !infoLoaded ? (
    <Skeleton width={'100%'} height={'5rem'} />
  ) : (
    <FormControl variant="filled" fullWidth className="mt-2">
      <InputLabel id="demo-simple-select-filled-label">Select Items</InputLabel>
      <Select
        className='select-items-cont'
        multiple
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={[props.itemsSelected]}
        onChange={handleChange}
        name="items_section"
      >
        {info.length > 0 ? (
        <FormGroup>
          <input type="text" className='search-items' placeholder='Serach For Items' onKeyUp={searchFunction}/>
          {info.map((item) => (
            <FormControlLabel  key={item.id} style={{paddingLeft: "10px"}} control={<Checkbox checked={checkExistance(props.itemsSelected, item.id)} value={item.id} onChange={(e) => handleChange(e, item)}/>} label={item.name} />   
            ))}
        </FormGroup>

        ) : (
          <MenuItem>No Category</MenuItem>
        )}
      </Select>
      <div className='tags-items-container'>
      { 

        props.itemsSelected.map((e,i)=>{
          return( 
            <div key={i} className='tag-items'>{e.name}</div>
            )
          })
      }
          </div>
    </FormControl>
  );
};

export default ItemSelect;
